import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Select, 
  MenuItem, 
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';

// Assuming you have these imports and configured
import { updateUserInfo } from '../graphql/mutations';
import { showToast } from '../redux/userSlice';
import { generateClient } from 'aws-amplify/api';
import { deleteUser } from 'aws-amplify/auth';
import { useDispatch } from 'react-redux';
import { listUserInfos } from '../graphql/queries';
import { PulseLoader } from 'react-spinners';

const UserManagementScreen = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
  const client= generateClient()
  const dispatch = useDispatch();
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token); 
   const [loader, setLoader] = useState(false);
  

  useEffect(() => {
    fetchAllUsers();
  }, []);
    const fetchAllUsers = async () => {
        setLoader(true);
      try {
        const response = await client.graphql({
          query: listUserInfos
        });
        const users = response.data.listUserInfos.items.filter(
          user => user.id !== userData.id // Exclude current user
        );
        setAllUsers(users);
        setFilteredUsers(users);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.error('Error fetching users:', error);
        dispatch(showToast({
          message: "Failed to fetch users",
          type: "error"
        }));
      }
    };
    const handleSearch = (searchTerm) => {
        const filtered = allUsers.filter(user =>
          user.user_name_first.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.user_name_second.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredUsers(filtered);
      };

  const handleRoleChange = async (userId, newRole) => {
    try {
        setLoader(true);
      await client.graphql({
        query: updateUserInfo,
        variables: {
          input: {
            id: userId,
            role: newRole
          }
        }
      });

      // Update local state
      const updatedUsers = users.map(user => 
        user.id === userId ? { ...user, role: newRole } : user
      );
      setUsers(updatedUsers);
fetchAllUsers();
setLoader(false);
      dispatch(showToast({
        message: "User role updated successfully",
        type: "success"
      }));
    } catch (error) {
        setLoader(false);
      console.error('Error updating user role:', error);
      dispatch(showToast({
        message: "Failed to update user role",
        type: "error"
      }));
    }
  };

  const handleDeleteUser = async () => {
    if (!selectedUser) return;

    try {
      await client.graphql({
        query: deleteUser,
        variables: {
          input: {
            id: selectedUser.id
          }
        }
      });

      // Remove user from local state
      const filteredUsers = users.filter(user => user.id !== selectedUser.id);
      setUsers(filteredUsers);

      dispatch(showToast({
        message: "User deleted successfully",
        type: "success"
      }));

      setOpenDeleteDialog(false);
    } catch (error) {
      console.error('Error deleting user:', error);
      dispatch(showToast({
        message: "Failed to delete user",
        type: "error"
      }));
    }
  };

  const userRoles = ['workspace_admin', 'workspace_member', 'super_admin'];
  return (
    <Box sx={{ 
      padding: 3, 
      backgroundColor: '#f5f5f5', 
      minHeight: '100vh',
      width: '100%' 
    }}>
      <Typography variant="h4" sx={{ mb: 3, fontWeight: 'bold' }}>
        User Management
      </Typography>

      <TableContainer component={Paper} elevation={3}>
        <Table>
          <TableHead sx={{ backgroundColor: 'black' }}>
            <TableRow>
              <TableCell sx={{ color: 'white',fontWeight: 'bold' }}>Name</TableCell>
              <TableCell sx={{ color: 'white',fontWeight: 'bold' }}>Email</TableCell>
              <TableCell sx={{ color: 'white',fontWeight: 'bold' }}>Role</TableCell>
              <TableCell sx={{ color: 'white',fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
        
          <TableBody>
          {loader ? (
  <TableRow>
    <TableCell colSpan={4} sx={{ textAlign: 'center', padding: '50px 0' }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "80vh"
        }}
      >
        <PulseLoader color="#ACD6EB" />
      </div>
    </TableCell>
  </TableRow>
) : (
            filteredUsers.map((user) => (
              <TableRow key={user.id} hover>
                <TableCell>{user.user_name_first}{user.user_name_second}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <Select
                    value={user.role}
                    onChange={(e) => handleRoleChange(user.id, e.target.value)}
                    size="small"
                  >
                    {userRoles.map((role) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <IconButton 
                    color="error"
                    onClick={() => {
                      setSelectedUser(user);
                      setOpenDeleteDialog(true);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
        )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Delete Confirmation Dialog */}
      <Dialog 
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Confirm User Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete {selectedUser?.name}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setOpenDeleteDialog(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteUser} 
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserManagementScreen;