import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Card, Grid } from "@mui/material";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { setLoading, setProductCount, showToast } from "../redux/userSlice";
import { generateClient } from "aws-amplify/api";
import "../Css/ChooseOrganization.css";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  listUserInfos,
  listTeamInvitations,
  getWorkspaceInfo,
} from "../graphql/queries";
import { signOut } from "aws-amplify/auth";
import { createCartItems } from "../graphql/mutations";
import uuid from "react-uuid";

const cardStyle = {
  margin: "4rem auto",
  width: "60%",
  textAlign: "center",
};
const organizationCard = {
  margin: "0.5rem auto",
  width: "40%",
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  borderRadius: "10px",
};
const workSpaceCard = {
  margin: "2rem auto",
  width: "100%",
  textAlign: "center",
};

const ChooseOrganization = () => {
  const client = generateClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const fromScreen = state?.fromScreen;
  const product = state?.product;
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const [userDetail, setUserDetail] = useState(null);
  const [workspaceDetail, setWorkspaceDetail] = React.useState(null);
  const [invitationDetail, setInvitationDetail] = React.useState(null);
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const [workspaceDetailByInvitation, setWorkspaceByInvitation] =
    React.useState(null);

  useEffect(() => {
    fetchData();
    fetchInvitation();
  }, []);
  const fetchUserData = async (selectedWorkspaceId) => {
    let nextToken = null;
    let allItems = [];

    const variables = {
      filter: {
        user_id: {
          eq: userData.sub,
        },
      },
    };

    do {
      const getAddressData = await client.graphql({
        query: listUserInfos,
        variables: {
          ...variables,
          nextToken: nextToken
        },
      });

      const items = getAddressData.data.listUserInfos.items;
      allItems = [...allItems, ...items];
      nextToken = getAddressData.data.listUserInfos.nextToken;
    } while (nextToken);

    return allItems;
  };
  const fetchData = async () => {
    try {
      // dispatch(setLoading(true));

      const result = await fetchUserData(selectedWorkspaceId);
      setUserDetail(result);
      if (result) {
        const workspaceIds = result?.map((id) => id.workspace_id);
        if (workspaceIds) {
          const userPromises = workspaceIds.map(async (wsInfo) => {
            const response = await client.graphql({
              query: getWorkspaceInfo,
              variables: { id: wsInfo, },
              limit: 1000,
            });
            return response?.data?.getWorkspaceInfo;
          });
          const WSdata = await Promise.all(userPromises);
          const flatArray = WSdata.flat();
          setWorkspaceDetail(flatArray);
        }
        dispatch(setLoading(false));
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error creating todo:", error);
    }
  };

  const fetchInvitation = async () => {
    dispatch(setLoading(true));
    try {
      const variables = {
        filter: {
          email: {
            eq: userData.email,
          },
        
        },
        limit: 1000,
      };

      const invitationData = await client.graphql({
        query: listTeamInvitations,
        variables: variables,
      });
      const result = invitationData.data.listTeamInvitations.items;
      const requiredResult = result?.filter((item) => item.status !== false);
      const uniqueWorkspaceMap = new Map();

      requiredResult.forEach((item) => {
        if (!uniqueWorkspaceMap.has(item.workspace_id)) {
          uniqueWorkspaceMap.set(item.workspace_id, item);
        }
      });

      const uniqueWorkspaceData = Array.from(uniqueWorkspaceMap.values());
      setInvitationDetail(uniqueWorkspaceData);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error creating todo:", error);
    } finally {
    }
  };

  useEffect(() => {
    if (invitationDetail?.length) {
      fetchWorkspaceDataByInvitation();
    }
  }, [invitationDetail]);

  const fetchWorkspaceDataByInvitation = async () => {
    try {
      dispatch(setLoading(true));
      if (invitationDetail) {
        const userPromises = invitationDetail.map(async (id) => {
          const response = await client.graphql({
            query: getWorkspaceInfo,
            variables: { id: id.workspace_id },
          });
          return response?.data?.getWorkspaceInfo;
        });
        const WSdata = await Promise.all(userPromises);
        const flatArray = WSdata.flat();
        setWorkspaceByInvitation(flatArray);
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const handleSelectedWorkspaceForTeam = (id) => {
    localStorage.setItem("selectedWorkspaceId", id);
    navigate("/team-login", {
      state: {
        WsID: id,
        invitationDetail: invitationDetail,
      },
    });
  };

  const setItemsToCart = async (selectedWorkspaceId) => {
    try {
      for (const productItem of product) {
        let id = uuid();
        const detail = {
          id: id,
          product_id: productItem.id,
          user_id: userData.sub,
          workspace_id: selectedWorkspaceId,
          image: productItem.image,
          name: productItem.name,
          period: productItem.period,
          price: productItem.price,
          type: productItem.type,
          plan: productItem.plan,
          quantity: productItem.quantity,
          sku: productItem.sku,
          every: productItem.every,
        };
        const saveItemInDB = await client.graphql({
          query: createCartItems,
          variables: { input: detail },
        });
        dispatch(setProductCount(saveItemInDB.data.createCartItems));
      }
    } catch (error) {
      console.log(error.message, "Error creating todo");
    }
  };

  const handleSelectedWorkspace = async (id) => {
    const userWorkspaceRole = userDetail?.filter(
      (ws) => ws.workspace_id === id
    );
    localStorage.setItem("selectedWorkspaceId", id);
    localStorage.setItem(
      "user",
      JSON.stringify({
        ...userData,
        role: userWorkspaceRole?.[0]?.role,
      })
    );
    if (userWorkspaceRole) {
      if (fromScreen === "external shop") {
        const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
        await setItemsToCart(selectedWorkspaceId);
        navigate("/cart");
      } else {
        navigate("/home");
      }
    }
  };

  async function handleSignOut() {
    try {
      localStorage.clear();
      await signOut();
      window.location.reload();
      navigate("/login");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  if (selectedWorkspaceId !== null) {
    return <Navigate to="/home" replace />;
  }

  return (
    <div>
      <div
        className="logout-Container"
        style={{
          margin: "2rem",
          fontSize: "1.5rem",
          color: "#605e61",
          display: "inline-flex",
          cursor: "pointer",
          alignItems: "center",
          gap: "0.3rem",
        }}
        onClick={handleSignOut}
      >
        <LogoutIcon style={{ fontSize: "1.8rem", color: "#605e61" }} />
        <p style={{ margin: "0px" }}>Logout</p>
      </div>
      <form style={cardStyle} className="card-container">
        <div
          style={{ margin: "0rem 0rem", textAlign: "center" }}
          className="img-container"
        >
          <img src="/faviconPNG.png" height="70px" width="430px" />
        </div>
        <div className="heading-container">
          <h1 style={{ color: "#1D1C1D", fontSize: "4rem", margin: "0rem" }}>
            Let's choose your Workspace
          </h1>
          <p style={{ fontSize: "1.5rem", color: "#605E61", margin: "0px" }}>
            Choose the one where you will find your closest, everyday
            collaborators.
          </p>
        </div>
        <Grid
          container
          spacing={0}
          style={workSpaceCard}
          className="work-space-card-container"
        >
          <>
            {workspaceDetail && workspaceDetail?.length
              ? workspaceDetail.map((workspace, index) => (
                <Grid item xs={12} sm={12} key={index}>
                  <Card
                    style={organizationCard}
                    className="work-space-card-sub-container"
                  >
                    <Grid container spacing={0}>
                      <Grid item xs={9}>
                        <div
                          className="work-space-card-sub-container-left"
                          style={{
                            display: "flex",
                            gap: "1rem",
                            padding: "0.7rem 2rem",
                          }}
                        >
                          <img
                            src={workspace?.logo}
                            height="65px"
                            width="65px"
                            className="org-img"
                            style={{ borderRadius: "6px" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                            className="org-desc"
                          >
                            <div>
                              <h3
                                style={{ margin: "0px", textAlign: "start" }}
                              >
                                {workspace?.name}
                              </h3>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0rem",
                              }}
                            >
                              <p
                                style={{ color: "#605E61", margin: "0px" }}
                              >{`${workspace?.user_id.length} member`}</p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          onClick={() =>
                            handleSelectedWorkspace(workspace.id)
                          }
                        >
                          Open
                        </Button>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))
              : null}
            {workspaceDetailByInvitation && workspaceDetailByInvitation?.length
              ? workspaceDetailByInvitation.map((itm, index) => (
                <Grid item xs={12} sm={12} key={index}>
                  <Card
                    style={organizationCard}
                    className="work-space-card-sub-container"
                  >
                    <Grid container spacing={0}>
                      <Grid item xs={9}>
                        <div
                          style={{
                            display: "flex",
                            gap: "1rem",
                            padding: "0.7rem 2rem",
                          }}
                          className="work-space-card-sub-container-left"
                        >
                          <img
                            src={itm.logo}
                            height="65px"
                            width="65px"
                            className="org-img"
                            style={{ borderRadius: "6px" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                            className="org-desc"
                          >
                            <div>
                              <h3
                                style={{ margin: "0px", textAlign: "start" }}
                              >
                                {itm.name}
                              </h3>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0rem",
                              }}
                            >
                              <p
                                style={{ color: "#605E61", margin: "0px" }}
                              >{`${itm.user_id.length} member`}</p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Button
                          onClick={() =>
                            handleSelectedWorkspaceForTeam(itm.id)
                          }
                        >
                          Join
                        </Button>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))
              : null}
          </>
        </Grid>
      </form>
    </div>
  );
};

export default ChooseOrganization;
