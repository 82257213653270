import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  Avatar,
  useMediaQuery,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Skeleton,
  Modal,
  CardContent,
  Divider,
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import Footer from "../components/Footer";
import SpeedIcon from "@mui/icons-material/Speed";
import HealthCheck from "../Assets/healthcheck-icon.png";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LanguageIcon from "@mui/icons-material/Language";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import { AreaChart, Area, Line, YAxis, ResponsiveContainer } from "recharts";
import CircularGauge from "../components/CircularGauge";
import { Link, useNavigate } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import {
  createSamRushProject,
  getSamRushProject,
} from "../utills/SamRushApis/samrushApi";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Advisor from "../Assets/1721063651853.jpg";
import { generateClient } from "aws-amplify/api";
import {
  getWorkspaceInfo,
  listProductCards,
  listProductVariations,
  listSubscriptions,
} from "../graphql/queries";
import { LoadingScreen } from "../components/LoadingSiteAudit";
import RefreshIcon from "@mui/icons-material/Refresh";
import { updateWorkspaceInfo } from "../graphql/mutations";
import { useDispatch, useSelector } from "react-redux";
import { selectPageSpeedData, setPageSpeedLoading, showToast } from "../redux/userSlice";
import HealthCheckDashboard from "./NewPerformancePage";
import PageSpeedGuage from "../components/Common/PageSpeedGuage";
import IssueDetailsModal from "../components/IssueDetailsModal";
import AllIssuesModal from "../components/AllIssuesModal";
import { fetchReportFromS3 } from "../utills/helper/helper";
import axios from "axios";
import { uploadData } from "aws-amplify/storage";

const organizationCard = {
  margin: "0.7rem 0rem",
  padding: "1rem 1.5rem",
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  minHeight: "300px",
  borderRadius: "10px",
};
const cardTitle = {
  fontSize: "1.5rem",
  margin: "0px",
  marginTop: "0rem",
};
const buttonStyle = {
  border: "none",
  color: "#000000de",
  textTransform: "initial",
  fontSize: "1rem",
  backgroundColor: "#F0F0F0",
};
const avsiorContainer = {
  display: "flex",
  justifyContent: "space-between",
};
const productImage = {
  width: "70%",
  objectFit: "contain",
  marginTop: 10,
};
const productName = {
  color: "#2895CF",
  margin: "0px",
  fontSize: "1.3rem",
};
const productContent = {
  margin: "1rem 1rem",
};
const productDesc = {
  fontSize: "1.1rem",
  fontWeight: "bold",
};
const productCard = {
  border: "1px solid #DCE5EC",
  borderRadius: "0px",
  cursor: "pointer",
  width: "60%",
};

const Performance = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const [isLoading, setIsLoading] = useState(true);
  const [projectData, setProjectData] = useState(null);
  const data = useSelector(selectPageSpeedData);
  
  const [pageSpeedData, setPageSpeedData] = useState(data);
  const [nonParsedPageSpeedData, setNonParsedPageSpeedData] = useState(null);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isAllIssuesModalOpen, setIsAllIssuesModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [product, setProduct] = useState(null);
  const [openModal, setOpenModal] = useState(false); // State to control modal visibility
  const [openModalForNewAudit, setOpenModalForNewAudit] = useState(false); // State to control modal visibility
  const [hasSubscription, setHasSubscription] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const navigate = useNavigate();
  const client = generateClient();
  const SemrushProjectName = localStorage.getItem("workSpaceName");
  const [workSpaceName, setWorkSpaceName] = useState(
    SemrushProjectName ? SemrushProjectName : "Rothbright"
  );
  const websiteUrl = localStorage.getItem("websiteUrl");
  const [workSpace, setWorkSpace] = useState("");
    const token = localStorage.getItem("user");
    const userData = JSON.parse(token);

  useEffect(() => {
   

    fetchWorkspaceInfo();
    fetchSubscriptions();
  }, [selectedWorkspaceId, projectId]); // Run when selectedWorkspaceId changes

  // Combine the two useEffects into one
  // useEffect(() => {
  //   if (!projectId) return; // Don't run if no projectId

  //   let intervalId;

  //   const initFetch = async () => {
  //     // Initial fetch
  //     const isDataReady = await fetchData();

  //     if (!isDataReady) {
  //       // Only set up polling if data isn't ready
  //       intervalId = setInterval(async () => {
  //         const isDataReady = await fetchData();

  //         if (isDataReady || isDataReady == undefined) {
  //           // If we have complete data, clear the interval
  //           clearInterval(intervalId);
  //         }
  //       }, 10000); // 10 seconds
  //     }
  //   };

  //   initFetch();

  //   // Cleanup function
  //   return () => {
  //     if (intervalId) {
  //       clearInterval(intervalId);
  //     }
  //   };
  // }, [projectId]); // Only depend on projectId

  // Remove the other useEffect that was watching projectData
  useEffect(() => {
    if(pageSpeedData==null){
      setIsLoading(false);
      fetchPlansById();
      fetchWorkspaceInfo()
    }
  }, [pageSpeedData]);
  const fetchWorkspaceInfo = async () => {
    try {
      if (!selectedWorkspaceId) return;
setIsLoading(true);
      const response = await client.graphql({
        query: getWorkspaceInfo,
        variables: {
          id: selectedWorkspaceId,
        },
      });

      const workspaceData = response.data.getWorkspaceInfo;
      setNonParsedPageSpeedData(workspaceData?.page_speed_report);
      setWorkSpace(workspaceData?.samrushDomainName);
      setWorkSpaceName(workspaceData?.name);
      if (workspaceData?.page_speed_report) {
        try {
          const reportMetadata = JSON.parse(workspaceData.page_speed_report);
          
          // If we have a reportUrl, fetch the full report from S3
          if (reportMetadata.reportUrl) {
            const fullReport = await fetchReportFromS3(reportMetadata.reportUrl);
            if (fullReport) {
              setPageSpeedData(fullReport);
              
            } else {
              // If S3 fetch fails, use the metrics from database as fallback
              setPageSpeedData({
                metrics: reportMetadata.metrics,
                timestamp: reportMetadata.timestamp,
                issues: { errors: [], warnings: [], notices: [] }
              });
            }
          }
          setIsLoading(false);
        } catch (error) {
          console.error("Error parsing page speed metadata:", error);
          setIsLoading(false);
          setPageSpeedData(null);
        }
      }
    } catch (error) {
      console.error("Error fetching workspace info:", error);
      setIsLoading(false);
    }
  };
  //Get Semrush Project Id
  const fetchData = async () => {
    try {
     
        const hasExistingSubscription = await fetchSubscriptions();
        console.log("The has subscription is::", hasExistingSubscription);
        if (!hasExistingSubscription[0]) {
          setHasSubscription(true);
          handleOpenModal();
        }
        return true; // Return true to indicate data is ready
      }
     catch (error) {
      console.error("Error fetching project data:", error);
      return false; // Return false on error to continue polling
    }
  };
  async function fetchProductVariationById(id) {
    let allItems = [];
    let nextToken = null;

    do {
      const getProductsData = await client.graphql({
        query: listProductCards,
        variables: {
          nextToken,
        },
      });
      const response = getProductsData.data.listProductCards.items;
      const item = response.find((item) => item.id === id);
      if (item) {
        return item; // Return the item immediately if found
      }
      allItems = [...allItems, ...response];
      nextToken = getProductsData.data.listProductVariations.nextToken;
    } while (nextToken);

    return null;
  }
  const fetchPlansById = async (products) => {
    try {
      const Semrush = "e981fc8a-af15-bef4-c816-c15a09cd08a6";
      const response = await fetchProductVariationById(Semrush);
      if (!response) {
        console.error("No product variation found with the given ID.");
        return;
      }
      const cleanedItem = { ...response };
      if (cleanedItem.categories) {
        cleanedItem.categories = JSON.parse(cleanedItem.categories);
      }
      if (cleanedItem.tags) {
        cleanedItem.tags = JSON.parse(cleanedItem.tags);
      }
      setProduct(cleanedItem);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
  const fetchSubscriptionsData = async (selectedWorkspaceId) => {
    let nextToken = null;
    let allItems = [];

    const variables = {
      filter: {
        workspace_id: {
          eq: selectedWorkspaceId,
        },
        product_id: { eq: "23ebdf93-1a34-4449-5200-3375d3690282" },
      },
      limit: 1000,
    };

    do {
      const getAddressData = await client.graphql({
        query: listSubscriptions,
        variables: {
          ...variables,
          nextToken: nextToken,
        },
      });

      const items = getAddressData.data.listSubscriptions.items;
      allItems = [...allItems, ...items];
      nextToken = getAddressData.data.listSubscriptions.nextToken;
    } while (nextToken);

    return allItems;
  };
  const fetchSubscriptions = async () => {
    try {
      const res = await fetchSubscriptionsData(selectedWorkspaceId);
      setHasSubscription(res);
      return res;
    } catch (error) {
      console.error("Error creating todo:", error);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true); // Function to open modal
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Function to close modal
  };

  const handleClick = async () => {
    dispatch(setPageSpeedLoading(true));
  setIsLoading(true);
    try {
      const api_key = "AIzaSyBtL911PwgosKvUClz7OnUpf9V8PCEknDs";
      const response = await axios.get(
        `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${encodeURIComponent(
          workSpace
        )}&key=${api_key}&category=performance&category=accessibility&category=best-practices&category=seo`
      );

      const { data } = response;
      const { lighthouseResult } = data;
      // Process all audits with detailed information
      const processedAudits = Object.entries(lighthouseResult.audits).map(
        ([key, audit]) => ({
          id: key,
          title: audit.title,
          description: audit.description,
          score: audit.score,
          displayValue: audit.displayValue,
          numericValue: audit.numericValue,
          details: {
            ...audit.details,
            opportunity: getOpportunityText(audit),
            items: processAuditItems(audit.details?.items),
          },
          warnings: audit.warnings,
          type: audit.scoreDisplayMode,
          recommendations: getRecommendations(audit),
        })
      );
      const categorizedIssues = {
        errors: processedAudits.filter(
          (audit) =>
            audit.score !== null &&
            audit.score < 0.5 &&
            audit.type !== "informative"
        ),
        warnings: processedAudits.filter(
          (audit) =>
            audit.score !== null &&
            audit.score >= 0.5 &&
            audit.score < 0.9 &&
            audit.type !== "informative"
        ),
        notices: processedAudits.filter(
          (audit) =>
            (audit.score !== null && audit.score >= 0.9) ||
            audit.type === "informative"
        ),
      };

      const metrics = {
        performance: Math.round(
          (data.lighthouseResult.categories.performance?.score || 0) * 100
        ),
        accessibility: Math.round(
          (data.lighthouseResult.categories.accessibility?.score || 0) * 100
        ),
        bestPractices: Math.round(
          (data.lighthouseResult.categories["best-practices"]?.score || 0) * 100
        ),
        seo: Math.round(
          (data.lighthouseResult.categories.seo?.score || 0) * 100
        ),
      };

      const report = {
        url: workSpace,
        timestamp: new Date().toISOString(),
        metrics: {
          performance: Math.round(
            (lighthouseResult.categories.performance?.score || 0) * 100
          ),
          accessibility: Math.round(
            (lighthouseResult.categories.accessibility?.score || 0) * 100
          ),
          bestPractices: Math.round(
            (lighthouseResult.categories["best-practices"]?.score || 0) * 100
          ),
          seo: Math.round((lighthouseResult.categories.seo?.score || 0) * 100),
        },
        issues: categorizedIssues,
        // metrics:metrics,
        // environment: lighthouseResult.environment,
        // configSettings: lighthouseResult.configSettings
      };

      // Convert report object to Blob/File
      const reportBlob = new Blob([JSON.stringify(report)], {
        type: "application/json",
      });
      const fileName = `pagespeed-report.json`;
      const reportFile = new File([reportBlob], fileName, {
        type: "application/json",
      });

      // Upload report using consistent path to overwrite existing file
      const upload = await uploadData({
        key: `${selectedWorkspaceId}/${userData.sub}/pagespeed-reports/pagespeed-report.json`,
        data: reportFile,
        options: {
          accessLevel: "public",
        },
      });

      // Wait for the upload to complete and get the result
      const uploadResult = await upload.result;
      console.log("Upload completed:", uploadResult);

      // Store the report location and metrics in the database
      const reportLocation = uploadResult.key || uploadResult.url;
      await client.graphql({
        query: updateWorkspaceInfo,
        variables: {
          input: {
            id: selectedWorkspaceId,
            page_speed_report: JSON.stringify({
              reportUrl: reportLocation,
              metrics: metrics,
              lastUpdated: new Date().toISOString(),
            }),
          },
        },
      });
await fetchWorkspaceInfo()
     
      setIsLoading(false);
      return metrics;
    } catch (error) {
      console.error("Error fetching PageSpeed data", error);
      dispatch(
        showToast({
          message:
            "Failed to analyze domain. Please check the URL and try again.",
          type: "error",
        })
      );
      setIsLoading(false);

      return null;
    } finally {
      dispatch(setPageSpeedLoading(false));
      setIsLoading(false);

    }
  };
  const getOpportunityText = (audit) => {
    if (audit.details?.overallSavingsMs) {
      return `Potential savings of ${audit.details.overallSavingsMs}ms`;
    }
    if (audit.details?.overallSavingsBytes) {
      return `Potential savings of ${(
        audit.details.overallSavingsBytes / 1024
      ).toFixed(2)}KB`;
    }
    return null;
  };
  const processAuditItems = (items) => {
    if (!items) return null;

    return items.map((item) => ({
      ...item,
      url: item.url || item.source,
      totalBytes: item.totalBytes || item.wastedBytes,
      wastedMs: item.wastedMs,
      source: item.source,
    }));
  };
  const getRecommendations = (audit) => {
    // Custom recommendations based on audit type
    const recommendations = {
      "first-contentful-paint": [
        "Eliminate render-blocking resources",
        "Minimize main-thread work",
        "Reduce server response time",
      ],
      "speed-index": [
        "Optimize images",
        "Minimize critical request depth",
        "Reduce JavaScript execution time",
      ],
      // Add more audit-specific recommendations
    };

    return recommendations[audit.id] || [];
  };
  // Updated data with API values
  const errorData = [
    { value: projectData?.errors || 0 },
    { value: projectData?.errors || 0 },
    { value: projectData?.errors || 0 },
    { value: projectData?.errors || 0 },
    { value: projectData?.errors || 0 },
    { value: projectData?.errors || 0 },
    { value: projectData?.errors || 0 },
  ];

  const warningData = [
    { value: projectData?.warnings || 0 },
    { value: projectData?.warnings || 0 },
    { value: projectData?.warnings || 0 },
    { value: projectData?.warnings || 0 },
    { value: projectData?.warnings || 0 },
    { value: projectData?.warnings || 0 },
    { value: projectData?.warnings || 0 },
  ];

  const noticeData = [
    { value: projectData?.notices || 0 },
    { value: projectData?.notices || 0 },
    { value: projectData?.notices || 0 },
    { value: projectData?.notices || 0 },
    { value: projectData?.notices || 0 },
    { value: projectData?.notices || 0 },
    { value: projectData?.notices || 0 },
  ];

  // Updated issues data from API
  const issuesData =
    projectData?.current_snapshot?.topIssues
      ?.map((issueId) => {
        const error = projectData?.current_snapshot?.errors.find(
          (e) => e.id === issueId
        );
        const warning = projectData?.current_snapshot?.warnings.find(
          (w) => w.id === issueId
        );

        if (error) {
          return {
            type: `Issue ID: ${issueId}`,
            count: error.count,
            severity: "errors",
          };
        }
        if (warning) {
          return {
            type: `Issue ID: ${issueId}`,
            count: warning.count,
            severity: "warnings",
          };
        }
        return null;
      })
      .filter(Boolean) || [];

  // Status data processing function
  const getStatusData = () => {
    if (!pageSpeedData) return { errors: 0, warnings: 0, notices: 0 };
    return {
      errors: pageSpeedData?.issues?.errors?.length || 0,
      warnings: pageSpeedData?.issues?.warnings?.length || 0,
      notices: pageSpeedData?.issues?.notices?.length || 0,
    };
  };

  // Format issues for display
  const formatIssues = () => {
    if (!pageSpeedData?.issues) return [];
    
    const allIssues = [
      ...(pageSpeedData.issues.errors || []).map((error) => ({
        title: error.title,
        description: error.description,
        severity: "errors",
        savings: error.displayValue,
        score: error.score,
        impact: error.impact,
        details: error.details,
        recommendations: error.recommendations || [],
        id: error.id,
        items: error.details?.items || [],
        type: error.type
      })),
      ...(pageSpeedData.issues.warnings || []).map((warning) => ({
        title: warning.title,
        description: warning.description,
        severity: "warnings",
        savings: warning.displayValue,
        score: warning.score,
        impact: warning.impact,
        details: warning.details,
        recommendations: warning.recommendations || [],
        id: warning.id,
        items: warning.details?.items || [],
        type: warning.type
      })),
    ];
    
    return allIssues.sort((a, b) => (a.severity === "errors" ? -1 : 1));
  };

  const statusData = getStatusData();
  const calculateTotalScore = () => {
    if (!pageSpeedData) return 0;
    const total =
      ((pageSpeedData?.metrics?.performance || 0) +
        (pageSpeedData?.metrics?.accessibility || 0) +
        (pageSpeedData?.metrics?.bestPractices || 0) +
        (pageSpeedData?.metrics?.seo || 0)) /
      4;
    return total;
  };
  const metrics = [
    { value: pageSpeedData?.metrics?.performance || 0, label: "Performance" },
    { value: pageSpeedData?.metrics?.accessibility || 0, label: "Accessibility" },
    { value: pageSpeedData?.metrics?.bestPractices || 0, label: "Best Practices" },
    { value: pageSpeedData?.metrics?.seo || 0, label: "SEO" },
  ];

  const handleIssueClick = (issue) => {
    setSelectedIssue(issue);
    setIsDetailsModalOpen(true);
  };

  const handleViewAll = () => {
    setIsAllIssuesModalOpen(true);
  };
  if ( !hasSubscription || !hasSubscription.length) {
    return (
      <HealthCheckDashboard
        domain={workSpace}
        handleClick={handleClick}
        setWorkSpace={setWorkSpace}
        hasSubscription={hasSubscription}
      />
    );
  }
  return (
    <Box sx={{ padding: 3, backgroundColor: "#f5f5f5", minHeight: "100vh" }}>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            width: "660px", // Increased width to accommodate two cards
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            <img src="/faviconPNG.png" alt="Rothbright" height="30" />
            <CancelOutlinedIcon
              onClick={handleCloseModal}
              sx={{ cursor: "pointer", color: "#999999", fontSize: "32px" }}
            />
          </Box>
          <Divider sx={{ width: "100%", my: 2 }} />

          <Box sx={{ display: "flex", gap: 3 }}>
            {" "}
            {/* Container for the two cards */}
            {/* SEMrush Card */}
            <Card
              sx={{ flex: 1, border: "1px solid #DCE5EC", borderRadius: 2 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={product?.image}
                      alt="SEMrush"
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                      }}
                    />
                    <Typography
                      variant="button"
                      sx={{
                        position: "absolute",
                        bottom: "50px", // Position from bottom
                        left: "50%",
                        transform: "translateX(-50%)", // Center horizontally
                        color: "#666666",
                        fontSize: "12px",
                        fontWeight: 700,
                        letterSpacing: "0.5px",
                        cursor: "pointer",
                        padding: "4px 8px",
                        textDecoration: "underline",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                      onClick={() => navigate(`/shop/${product?.slug}`)}
                    >
                      VIEW DETAILS
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Card>
            {/* Advisor Card */}
            <Card sx={{ flex: 1, p: 2, border: "none", boxShadow: "none" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Avatar src={Advisor} sx={{ width: 100, height: 100 }} />
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Marcus Frye
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    marcus@rothbright.com
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "#00A651",
                    "&:hover": { backgroundColor: "#008C44" },
                    textTransform: "none",
                    py: 1.5,
                    mt: 2,
                  }}
                  onClick={() =>
                    window.open(
                      "https://calendly.com/d/cpng-nc4-t7n/rothbright-advisor-calendar",
                      "_blank"
                    )
                  }
                >
                  Connect with Advisor
                </Button>
              </Box>
            </Card>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openModalForNewAudit}
        onClose={() => setOpenModalForNewAudit(false)}
      >
        <Box
          sx={{
            width: "60%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2, // Optional: rounded corners
          }}
        >
          <h3 style={{ marginBottom: "1rem" }}>Enter Domain Name</h3>
          <TextField
            id="outlined-basic"
            label="Domain"
            variant="outlined"
            onChange={(e) => setWorkSpace(e.target.value)}
            value={workSpace}
            fullWidth
            sx={{
              backgroundColor: "#fff",
              marginBottom: "1rem",
            }}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              onClick={() => handleClick()} // Replace with your button logic
              sx={{ whiteSpace: "nowrap" }}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 3,
          padding: "20px",
        }}
      >
        {/* Title and Date Section */}
        <Box sx={{ flexShrink: 0 }}>
          <div style={{ display: "flex", gap:2 }}>

        <img src={HealthCheck} style={{ height: 50, width: 50 }} />

          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              color: "#2c3e50",
              marginBottom: "4px",
            }}
            >
            <strong>Health</strong>Check+{" "}
          </Typography>
            </div>
          <Typography
            variant="body2"
            sx={{
              color: "#94a3b8",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <AccessTimeIcon sx={{ fontSize: 16 }} />
            Checked on:{" "}
            {pageSpeedData?.timestamp
              ? new Date(pageSpeedData?.timestamp).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
              : "Not checked yet"}
          </Typography>
        </Box>

        {/* URL TextField - Wrapped in Box to control width */}

        <Box sx={{ width: "20%", ml: "3%" }}>
          {" "}
          {/* Fixed width container */}
          <TextField
            value={workSpace}
            onChange={(e) => setWorkSpace(e.target.value)}
            variant="outlined"
            fullWidth
            label="Domain"
            sx={{
              borderRadius: 5,
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#f8fafc",
                "&:hover": {
                  backgroundColor: "#f1f5f9",
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LanguageIcon sx={{ color: "#64748b" }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {/* Regenerate Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleClick()}
          startIcon={<RefreshIcon />}
          sx={{
            textTransform: "none",
            fontWeight: 500,
            "&:hover": {
              backgroundColor: "#1e7ab0",
            },
            minWidth: "160px",
            height: "40px",
            "&.Mui-disabled": {
              backgroundColor: "#e0e0e0",
              color: "#9e9e9e",
            },
          }}
        >
          {isLoading || projectData?.status === "processing"
            ? "Processing..."
            : "Regenerate Report"}
        </Button>
        <Button
          sx={{ marginLeft: "auto" }}
          variant="contained"
          color="primary"
          onClick={handleOpenModal}
        >
          {" "}
          {/* Updated onClick */}
          Have Questions?
        </Button>
      </Box>

      <Grid container spacing={3}>
        {isLoading && (
          <Grid item xs={12}>
            <LoadingScreen
              websiteUrl={workSpace}
              open={isLoading}
              onClose={() => setIsLoading(false)}
            />
          </Grid>
        )}

        {/* Site Health Gauge */}
        <Grid item xs={12} md={3} lg={3}>
          {isLoading ? null : (
            <Card style={{ ...organizationCard, height: "92%" }}>
              
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="h6">Health Check+</Typography>
                <InfoIcon sx={{ color: "grey.500" }} />
              </Box>
              <CircularGauge value={calculateTotalScore()} />
            </Card>
          )}
        </Grid>

        <Grid item xs={12} md={3} lg={3}>
          {isLoading ? null : (
            <Card style={organizationCard}>
             
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <Grid sx={{ padding: 0 }} item xs={12} md={8}>
                  <Grid container spacing={1}>
                    {metrics.map((metric, index) => (
                      <Grid item xs={6} key={index}>
                        <Box sx={{p:{xs:1,sx:2,lg:2,md:3}}}  style={{ textAlign: "center" }}>
                          <PageSpeedGuage
                            value={metric.value}
                            size={80}
                            thickness={8}
                          />
                          <Typography
                           sx={{
                            mt: { xs: 0.5, sm: 1 },
                            fontSize: {
                                xs: '12px',
                                sm: '14px',
                                md: '16px'
                            },
                            color: "#666",
                        }}
                          >
                            {metric.label}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Box>
            </Card>
          )}
        </Grid>
        {/* Issues Table */}
        <Grid item xs={12} md={4} lg={6}>
          {isLoading ? null : (
            <Card style={organizationCard}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="h6">Top Issues</Typography>
                <Button variant="contained" onClick={handleViewAll}>
                  View All
                </Button>
              </Box>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Issue Details</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Impact</TableCell>
                    <TableCell>Elements Affected</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formatIssues().slice(0, 4).map((issue, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          {issue.severity === "errors" ? (
                            <ErrorIcon sx={{ color: "#FF4444" }} />
                          ) : (
                            <WarningIcon sx={{ color: "#FFA726" }} />
                          )}
                          <Tooltip
                            title={`ID: ${issue.id || 'N/A'}`}
                            placement="top-start"
                            arrow
                            enterDelay={500}
                          >
                            <Typography
                              onClick={() => handleIssueClick(issue)}
                              sx={{
                                maxWidth: "250px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                cursor: "pointer",
                              }}
                            >
                              {issue.title}
                              <Typography
                                component="span"
                                sx={{
                                  ml: 1,
                                  color: issue.severity === "errors" ? "#FF4444" : "#FFA726",
                                  fontSize: "0.75rem",
                                }}
                              >
                                ({issue.severity})
                              </Typography>
                            </Typography>
                          </Tooltip>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title={issue.description}
                          placement="top-start"
                          arrow
                          enterDelay={500}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              maxWidth: "250px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              cursor: "pointer",
                            }}
                          >
                            {issue.description}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color: issue.impact === "minor" ? "#FFA726" : 
                                  issue.impact === "serious" ? "#FF4444" : "#2196F3",
                            textTransform: "capitalize",
                          }}
                        >
                          {issue.impact || "N/A"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title={issue.items?.map(item => item.snippet || item.nodeLabel).join('\n') || 'No elements affected'}
                          placement="top-start"
                          arrow
                          enterDelay={500}
                        >
                          <Typography>
                            {issue.items?.length || 0} element(s)
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Button
                              onClick={() => handleIssueClick(issue)}
                          variant="contained"
                          size="small"
                          sx={{
                            backgroundColor: issue.severity === "errors" ? "#FF4444" : "#FFA726",
                            "&:hover": {
                              backgroundColor: issue.severity === "errors" ? "#FF0000" : "#FF9100",
                            },
                          }}
                        >
                         View Issue
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
          )}
        </Grid>

        {/* Statistics Cards */}
        {[1, 2, 3].map((item) => (
          <Grid item xs={12} md={4} key={item}>
            {isLoading ? (
              <Paper
                elevation={0}
                sx={{ p: 3, backgroundColor: "white", borderRadius: 2 }}
              >
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <Skeleton
                    variant="circular"
                    width={24}
                    height={24}
                    sx={{ mr: 1 }}
                  />
                  <Skeleton variant="text" width={80} height={32} />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Skeleton variant="text" width={120} height={48} />
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html:
                        item === 1
                          ? "We're analyzing errors..."
                          : item === 2
                          ? "Checking for warnings..."
                          : "Scanning for notices...",
                    }}
                    sx={{ color: "grey.500", fontSize: "0.875rem" }}
                  />
                </Box>
                <Skeleton variant="rectangular" height={80} />
              </Paper>
            ) : (
              <Card
                style={organizationCard}
                sx={{ boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px" }}
              >
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  {item === 1 ? (
                    <ErrorIcon sx={{ color: "#FF4444", mr: 1 }} />
                  ) : item === 2 ? (
                    <WarningIcon sx={{ color: "#FFA726", mr: 1 }} />
                  ) : (
                    <InfoIcon sx={{ color: "#2196F3", mr: 1 }} />
                  )}
                  <Typography variant="h6">
                    {item === 1
                      ? "Errors"
                      : item === 2
                      ? "Warnings"
                      : "Notices"}
                  </Typography>
                </Box>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  {item === 1
                    ? statusData.errors
                    : item === 2
                    ? statusData.warnings
                    : statusData?.notices}
                  <span style={{ color: "#4CAF50", fontSize: "1rem" }}>
                    {item === 1
                      ? ` ${statusData?.errors || 0}`
                      : item === 2
                      ? ` ${statusData?.warnings || 0}`
                      : ` ${statusData?.notices || 0}`}
                  </span>
                </Typography>
                <Box sx={{ position: "relative", height: 80 }}>
                  <Typography
                    variant="caption"
                    sx={{ position: "absolute", left: 0, top: 0 }}
                  >
                    {item === 1 ? "15" : item === 2 ? "395" : "221"}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ position: "absolute", left: 0, bottom: 0 }}
                  >
                    0
                  </Typography>
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                      data={
                        item === 1
                          ? errorData
                          : item === 2
                          ? warningData
                          : noticeData
                      }
                      margin={{ top: 5, right: 5, bottom: 5, left: 15 }}
                    >
                      <YAxis
                        hide
                        domain={[0, item === 1 ? 15 : item === 2 ? 395 : 221]}
                      />
                      <Area
                        type="monotone"
                        dataKey="value"
                        fill={
                          item === 1
                            ? "#FF444420"
                            : item === 2
                            ? "#FFA72620"
                            : "#2196F320"
                        }
                        stroke={
                          item === 1
                            ? "#FF4444"
                            : item === 2
                            ? "#FFA726"
                            : "#2196F3"
                        }
                        strokeWidth={1}
                        dot={{
                          r: 2,
                          fill:
                            item === 1
                              ? "#FF4444"
                              : item === 2
                              ? "#FFA726"
                              : "#2196F3",
                          strokeWidth: 0,
                        }}
                        activeDot={{ r: 2 }}
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </Box>
              </Card>
            )}
          </Grid>
        ))}
      </Grid>
      <IssueDetailsModal
        open={isDetailsModalOpen}
        handleClose={() => setIsDetailsModalOpen(false)}
        issue={selectedIssue}
      />

      <AllIssuesModal
        open={isAllIssuesModalOpen}
        handleClose={() => setIsAllIssuesModalOpen(false)}
        issues={formatIssues()}
        onIssueClick={(issue) => {
          setSelectedIssue(issue);
          setIsAllIssuesModalOpen(false);
          setIsDetailsModalOpen(true);
        }}
      />
      {/* Footer */}
      {!isMobile && (
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Performance;
