import React from 'react';
import { Box, Typography, Avatar, IconButton, MenuItem, TextField } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const UserCard = ({ user, onAddMember }) => {
    const [role, setRole] = React.useState('');

    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 2,
            borderBottom: '1px solid #eee'
        }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Avatar
                    src={user.display_picture}
                    alt={`${user.user_name_first} ${user.user_name_second}`}
                />
                <Box>
                    <Typography variant="subtitle1">
                        {user.user_name_first} {user.user_name_second}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {user.workspace}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {user.email}
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <TextField
                    select
                    size="small"
                    value={role}
                    onChange={handleRoleChange}
                    sx={{ width: 200 }}
                    placeholder="Select Role"
                >
                    <MenuItem value="workspace_member">Workspace Member</MenuItem>
                    <MenuItem value="workspace_admin">Workspace Admin</MenuItem>
                </TextField>
                <IconButton
                    color="primary"
                    onClick={() => onAddMember(user, role)}
                    disabled={!role}
                >
                    <AddCircleIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default UserCard; 