import React, { useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    Button, TextField, Box, Divider, MenuItem,
    Typography, IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import UserCard from './UserCard';

const TeamDialog = ({
    open,
    onClose,
    users,
    onAddMember,
    onInviteByEmail,
    handleSearch
}) => {
    const [showEmailInput, setShowEmailInput] = useState(false);
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [emailError, setEmailError] = useState('');

    const handleEmailInvite = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email');
            return;
        }
        if (!role) {
            setEmailError('Please select a role');
            return;
        }
        onInviteByEmail(email, role);
        setEmail('');
        setRole('');
        setShowEmailInput(false);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Add Team Members</DialogTitle>
            <DialogContent>
                <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <TextField
                        variant="outlined"
                        placeholder="Search users..."
                        size="small"
                        sx={{ flex: 1, mr: 2 }}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => setShowEmailInput(true)}
                        variant="outlined"
                    >
                        Invite by Email
                    </Button>
                </Box>

                {showEmailInput && (
                    <Box sx={{ mb: 2, p: 2, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>Invite New Member</Typography>
                        <TextField
                            fullWidth
                            size="small"
                            label="Email Address"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailError('');
                            }}
                            error={!!emailError}
                            helperText={emailError}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            select
                            fullWidth
                            size="small"
                            label="Select Role"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            sx={{ mb: 2 }}
                        >
                            <MenuItem value="workspace_member">Workspace Member</MenuItem>
                            <MenuItem value="workspace_admin">Workspace Admin</MenuItem>
                        </TextField>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button variant="contained" onClick={handleEmailInvite}>
                                Send Invitation
                            </Button>
                            <Button onClick={() => setShowEmailInput(false)}>
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                )}

                <Divider sx={{ my: 2 }} />

                <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
                    {users.map((user) => (
                        <UserCard
                            key={user.id}
                            user={user}
                            onAddMember={onAddMember}
                        />
                    ))}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default TeamDialog; 