import { Modal, Box, Typography, Button, Chip, Stack } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '80vh',
  overflow: 'auto',
};

const IssueDetailsModal = ({ open, handleClose, issue }) => {
  if (!issue) return null;
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2" gutterBottom>
          {issue.title}
        </Typography>
        
        <Stack spacing={2}>
          <Typography color="text.secondary">
            <strong>Impact:</strong> {issue.impact || 'N/A'}
          </Typography>

          {issue.tags && issue.tags.length > 0 && (
            <Box>
              <Typography variant="subtitle2" gutterBottom>Tags:</Typography>
              <Stack direction="row" spacing={1}>
                {issue.tags.map((tag, index) => (
                  <Chip key={index} label={tag} size="small" />
                ))}
              </Stack>
            </Box>
          )}

          <Typography>
            <strong>Description:</strong><br />
            {issue.description}
          </Typography>

          {issue.items && issue.items.length > 0 && (
            <Box>
              <Typography variant="subtitle2" gutterBottom>Failing Elements:</Typography>
              {issue.items.map((item, index) => (
                <Box key={index} sx={{ mt: 1, bgcolor: '#f5f5f5', p: 1, borderRadius: 1 }}>
                  <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                    Selector: {item.node?.selector}
                  </Typography>
                  {item.node?.explanation && (
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {item.node.explanation}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          )}

          {issue.recommendations && issue.recommendations.length > 0 && (
            <Box>
              <Typography variant="subtitle2" gutterBottom>Recommendations:</Typography>
              <ul>
                {issue.recommendations.map((rec, index) => (
                  <li key={index}>
                    <Typography variant="body2">{rec}</Typography>
                  </li>
                ))}
              </ul>
            </Box>
          )}
        </Stack>

        <Button 
          sx={{ mt: 3 }} 
          variant="contained" 
          onClick={handleClose}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default IssueDetailsModal;
