import { downloadData } from "aws-amplify/storage";

export const fetchReportFromS3 = async (reportUrl) => {
  try {
    const download = await downloadData({
      key: reportUrl,
      options: {
        accessLevel: "public"
      }
    });
    
    // Wait for the download to complete and get the result
    const response = await download.result;
    // Convert the response to text
    const text = await response.body.text();
    return JSON.parse(text);
  } catch (error) {
    console.error("Error fetching report from S3:", error);
    return null;
  }
};