import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Grid,
  List,
  ListItem,
  ListItemText,
  Popover,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Footer from "../components/Footer";
import { setLoading, showToast } from "../redux/userSlice";
import { useDispatch } from "react-redux";
import { generateClient } from "aws-amplify/api";
import {
  listSubscriptions,
  listUserInfos,
  listWorkspaceInfos,
} from "../graphql/queries";
import { WorkspaceRevenueModal } from "../components/WorkspaceRevenueModal";

const datePickerStyle = {
  marginRight: "10px",
  "& .MuiInputBase-root": {
    height: "40px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#1976d2",
    },
    "&:hover fieldset": {
      borderColor: "#1976d2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1976d2",
    },
  },
};

const container = {
  margin: "2rem 4rem",
};
const title = {
  fontSize: "2rem",
  margin: "0px",
  marginBottom: "1rem",
};
const cardTitle = {
  fontSize: "3rem",
  margin: "0px",
  marginTop: "0rem",
};
const organizationCard = {
  margin: "0.7rem 0rem",
  padding: "2.5rem 2rem",
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  minHeight: "300px",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};
const cardDesc = {
  lineHeight: "1.65rem",
  color: "#605E61",
};

const WorkspaceStatistics = () => {
  const client = generateClient();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const getOneMonthAgoDate = () => {
    const today = new Date();
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(today.getMonth() - 1);
    return oneMonthAgo.toISOString().split('T')[0];
  };
  
  const [totalWorkspace, setTotalWorkspace] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalSubsRevenue, setTotalSubsRevenue] = useState(0);
  const [appliedStartDate, setAppliedStartDate] = useState(getOneMonthAgoDate());
  const [appliedEndDate, setAppliedEndDate] = useState(getCurrentDate());
 const [tempStartDate, setTempStartDate] = useState(getOneMonthAgoDate());
   const [tempEndDate, setTempEndDate] = useState(getCurrentDate());
  const [loading, setLoading] = useState(false);
  const [totalSimpleProductRev, setTotalSimpleProductRev] = useState(0);
  const [topRevenueWorkspaces, setTopRevenueWorkspaces] = useState([]);
  const [allWorkSpaces, setAllWorkSpaces] = useState([]);
  const [openRevenueModal, setOpenRevenueModal] = useState(false);
  const [allWorkspaceRevenues, setAllWorkspaceRevenues] = useState([]);
 
  useEffect(() => {
    fetchWorkspacesNumber();
    fetchSubscriptionRevenue();
    fetchUserCount();
  }, []);

  const applyDateRange = () => {
    setAppliedStartDate(tempStartDate);
    setAppliedEndDate(tempEndDate);
    fetchWorkspacesNumber();
    fetchSubscriptionRevenue();
    fetchUserCount();
  };

  //

  // const isDateInRange = (dateString, startDate, endDate) => {
  //   const date = new Date(dateString);
  //   date.setHours(0, 0, 0, 0);

  //   const start = startDate ? new Date(startDate) : null;
  //   if (start) start.setHours(0, 0, 0, 0);

  //   const end = endDate ? new Date(endDate) : null;
  //   if (end) end.setHours(23, 59, 59, 999);

  //   return (!start || date >= start) && (!end || date <= end);
  // };
  const handleOpenRevenueModal = () => setOpenRevenueModal(true);
  const handleCloseRevenueModal = () => setOpenRevenueModal(false);
  async function fetchAllUsers() {
    let allItems = [];
    let nextToken = null;

    do {
      const getUsers = await client.graphql({
        query: listUserInfos,
        variables: {
          nextToken: nextToken,
        },
      });
      const response = getUsers?.data?.listUserInfos?.items || [];
      allItems = [...allItems, ...response];
      nextToken = getUsers?.data?.listUserInfos?.nextToken;
    } while (nextToken);
    return allItems;
  }

  const fetchUserCount = async () => {
    try {
      setLoading(true);
      const allUsers = await fetchAllUsers();

      const filteredUsers = allUsers.filter((user) => {
        const createdAt = new Date(user.joined_at);
        return (
          (!appliedStartDate || createdAt >= new Date(appliedStartDate)) &&
          (!appliedEndDate || createdAt <= new Date(appliedEndDate))
        );
      });

      setTotalUsers(allUsers.length);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error fetching users:", error);
    }
  };

  async function fetchAllWorkspaces() {
    let allItems = [];
    let nextToken = null;

    do {
      const getWorkspaces = await client.graphql({
        query: listWorkspaceInfos,
        variables: {
          nextToken: nextToken,
        },
      });
      const response = getWorkspaces?.data?.listWorkspaceInfos?.items || [];
      allItems = [...allItems, ...response];
      nextToken = getWorkspaces?.data?.listWorkspaceInfos?.nextToken;
    } while (nextToken);
    return allItems;
  }

  const fetchWorkspacesNumber = async () => {
    try {
      setLoading(true);
      const allWorkspaces = await fetchAllWorkspaces();
      setAllWorkSpaces(allWorkspaces.length);
      const filteredWorkspaces = allWorkspaces.filter((workspace) => {
        const createdAt = new Date(workspace.created_at);
        return (
          (!appliedStartDate || createdAt >= new Date(appliedStartDate)) &&
          (!appliedEndDate || createdAt <= new Date(appliedEndDate))
        );
      });

      setTotalWorkspace(filteredWorkspaces.length);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error fetching workspaces:", error);
    }
  };
  async function fetchAllSubscriptionRevenue() {
    let allItems = [];
    let nextToken = null;

    do {
      const getSubsTotal = await client.graphql({
        query: listSubscriptions,
        variables: {
          nextToken: nextToken,
        },
      });
      const response = getSubsTotal?.data?.listSubscriptions?.items || [];
      allItems = [...allItems, ...response];
      nextToken = getSubsTotal?.data?.listSubscriptions?.nextToken;
    } while (nextToken);
    return allItems;
  }

  // const fetchSubscriptionRevenue = async () => {
  //   try {
  //     setLoading(true);
  //     const allSubscriptions = await fetchAllSubscriptionRevenue();

  //     const filteredSubscriptions = allSubscriptions.filter((subscription) => {
  //       const createdAt = new Date(subscription.created_at);
  //       return (
  //         (!appliedStartDate || createdAt >= new Date(appliedStartDate)) &&
  //         (!appliedEndDate || createdAt <= new Date(appliedEndDate))
  //       );
  //     });

  //     let variableSubsRevenue = 0;
  //     let simpleProductRevenue = 0;
  //     let workspaceRevenues = {};

  //     filteredSubscriptions.forEach((subscription) => {
  //       const productQuantity =
  //         parseInt(subscription.product_quantity, 10) || 0;
  //       const productPrice = parseFloat(subscription.product_price) || 0;
  //       const revenue = productQuantity * productPrice;

  //       if (subscription.product_type === "variable subscription") {
  //         variableSubsRevenue += revenue;

  //         // Accumulate revenue for each workspace
  //         if (subscription.workspace_id) {
  //           if (!workspaceRevenues[subscription.workspace_id]) {
  //             workspaceRevenues[subscription.workspace_id] = 0;
  //           }
  //           workspaceRevenues[subscription.workspace_id] += revenue;
  //         }
  //       } else {
  //         simpleProductRevenue += revenue;
  //       }
  //     });

  //     setTotalSubsRevenue(variableSubsRevenue);
  //     setTotalSimpleProductRev(simpleProductRevenue);

  //     const sortedWorkspaces = Object.entries(workspaceRevenues)
  //       .sort(([, a], [, b]) => b - a)
  //       .slice(0, 3);

  //     const workspaceIds = sortedWorkspaces.map(([id]) => id);
  //     const workspaceNames = await fetchTop5WorkspaceNames(workspaceIds);

  //     const topWorkspaces = sortedWorkspaces.map(([id, revenue]) => ({
  //       id,
  //       name: workspaceNames[id] || "Unknown",
  //       revenue,
  //     }));

  //     setTopRevenueWorkspaces(topWorkspaces);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     dispatch(showToast({ message: error.message, type: "error" }));
  //     console.error("Error fetching subscription revenue:", error);
  //   }
  // };

  const fetchSubscriptionRevenue = async () => {
    try {
      setLoading(true);
      const allSubscriptions = await fetchAllSubscriptionRevenue();
      
      // Calculate filtered revenue for dashboard display
      const filteredSubscriptions = allSubscriptions.filter((subscription) => {
        const createdAt = new Date(subscription.created_at);
        return (
          (!appliedStartDate || createdAt >= new Date(appliedStartDate)) &&
          (!appliedEndDate || createdAt <= new Date(appliedEndDate))
        );
      });
  
      // Calculate filtered metrics
      let variableSubsRevenue = 0;
      let simpleProductRevenue = 0;
      let filteredWorkspaceRevenues = {};
  
      filteredSubscriptions.forEach((subscription) => {
        const productQuantity = parseInt(subscription.product_quantity, 10) || 0;
        const productPrice = parseFloat(subscription.product_price) || 0;
        const revenue = productQuantity * productPrice;
  
        if (subscription.product_type === "variable subscription") {
          variableSubsRevenue += revenue;
          if (subscription.workspace_id) {
            if (!filteredWorkspaceRevenues[subscription.workspace_id]) {
              filteredWorkspaceRevenues[subscription.workspace_id] = {
                revenue: 0,
                planName: subscription.product_name || 'Unknown Plan'
              };
            }
            filteredWorkspaceRevenues[subscription.workspace_id].revenue += revenue;
          }
        } else {
          simpleProductRevenue += revenue;
        }
      });
  
      // Calculate all-time revenue for all workspaces
      let allTimeWorkspaceRevenues = {};
      allSubscriptions.forEach((subscription) => {
        if (subscription.product_type === "variable subscription") {
          const productQuantity = parseInt(subscription.product_quantity, 10) || 0;
          const productPrice = parseFloat(subscription.product_price) || 0;
          const revenue = productQuantity * productPrice;
  
          if (subscription.workspace_id) {
            if (!allTimeWorkspaceRevenues[subscription.workspace_id]) {
              allTimeWorkspaceRevenues[subscription.workspace_id] = {
                revenue: 0,
                planName: subscription.product_name || 'Unknown Plan',
                subscriptionCount: 0
              };
            }
            allTimeWorkspaceRevenues[subscription.workspace_id].revenue += revenue;
            allTimeWorkspaceRevenues[subscription.workspace_id].subscriptionCount += 1;
            
            // Update plan name to the most recent subscription's plan
            if (new Date(subscription.created_at) > new Date(allTimeWorkspaceRevenues[subscription.workspace_id].lastUpdate || 0)) {
              allTimeWorkspaceRevenues[subscription.workspace_id].planName = subscription.product_name || 'Unknown Plan';
              allTimeWorkspaceRevenues[subscription.workspace_id].lastUpdate = subscription.created_at;
            }
          }
        }
      });
  
      // Get workspace names for all workspaces
      const allWorkspaceIds = [...new Set([
        ...Object.keys(filteredWorkspaceRevenues),
        ...Object.keys(allTimeWorkspaceRevenues)
      ])];
      const workspaceNames = await fetchTop5WorkspaceNames(allWorkspaceIds);
  
      // Create full workspace revenue list for modal
      const fullWorkspaceList = Object.keys(allTimeWorkspaceRevenues).map(id => ({
        id,
        name: workspaceNames[id] || "Unknown",
        revenue: allTimeWorkspaceRevenues[id].revenue,
 
        subscriptionCount: allTimeWorkspaceRevenues[id].subscriptionCount
      })).sort((a, b) => b.revenue - a.revenue);
  
      // Create filtered list for dashboard display
      const filteredWorkspaceList = Object.keys(filteredWorkspaceRevenues).map(id => ({
        id,
        name: workspaceNames[id] || "Unknown",
        revenue: filteredWorkspaceRevenues[id].revenue,
        planName: filteredWorkspaceRevenues[id].planName
      })).sort((a, b) => b.revenue - a.revenue);
  
      setAllWorkspaceRevenues(fullWorkspaceList);
      setTotalSubsRevenue(variableSubsRevenue);
      setTotalSimpleProductRev(simpleProductRevenue);
      setTopRevenueWorkspaces(filteredWorkspaceList.slice(0, 3));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error fetching subscription revenue:", error);
    }
  };
  const fetchTop5WorkspaceNames = async (workspaceIds) => {
    const workspaceNames = {};
setLoading(true);
    try {
      for (const id of workspaceIds) {
        const variables = {
          filter: {
            id: {
              eq: id,
            },
          },
        };

        const workspaceData = await client.graphql({
          query: listWorkspaceInfos,
          variables: variables,
        });

        const workspace = workspaceData.data.listWorkspaceInfos.items[0];
        if (workspace) {
          workspaceNames[id] = workspace.name;
        }
      }
setLoading(false);
      return workspaceNames;
    } catch (error) {
      setLoading(false);
      console.error("Error fetching workspace names:", error);
      return workspaceNames;
    }
  };

  return (
    <div style={container} className="main-container">
       <WorkspaceRevenueModal
        open={openRevenueModal}
        handleClose={handleCloseRevenueModal}
        workspaceRevenues={allWorkspaceRevenues}
      />
      {loading ? (
        <Stack spacing={3}>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            height={50}
            animation="wave"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "2rem",
            }}
          >
            <Skeleton
              variant="rounded"
              width={"33%"}
              height={300}
              animation="wave"
            />
            <Skeleton
              variant="rounded"
              width={"33%"}
              height={300}
              animation="wave"
            />
            <Skeleton
              variant="rounded"
              width={"33%"}
              height={300}
              animation="wave"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "2rem",
            }}
          >
            <Skeleton
              variant="rounded"
              width={"33%"}
              height={300}
              animation="wave"
            />
            <Skeleton
              variant="rounded"
              width={"33%"}
              height={300}
              animation="wave"
            />
            <Skeleton
              variant="rounded"
              width={"33%"}
              height={300}
              animation="wave"
            />
          </div>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            height={50}
            animation="wave"
          />
        </Stack>
      ) : (
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={title} className="title-heading">
                Workspaces Overview
              </p>
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  type="date"
                  value={tempStartDate}
                  onChange={(e) => setTempStartDate(e.target.value)}
                  sx={datePickerStyle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Start Date"
                />
                <TextField
                  type="date"
                  value={tempEndDate}
                  onChange={(e) => setTempEndDate(e.target.value)}
                  sx={datePickerStyle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="End Date"
                />
                <Button
                  variant="contained"
                  style={{
                    textTransform: "none",
                    height: "40px",
                    marginLeft: "10px",
                    backgroundColor: "#1976d2",
                    color: "white",
                  }}
                  onClick={applyDateRange}
                >
                  Apply Date Range
                </Button>
                <Button
        variant="outlined"
        style={{
          textTransform: "none",
          height: "40px",
          marginLeft: "10px",
        }}
        onClick={handleOpenRevenueModal}
      >
        View All Revenue
      </Button>
              </div>
            </div>
          </Grid>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Card style={organizationCard} className="dashboard-section-card">
                <p style={cardDesc} className="card-desc">
                  <b>New Workspaces</b>
                </p>
                <h4 style={cardTitle} className="card-title">
                  {totalWorkspace}
                </h4>
              </Card>
            </Grid>
           
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Card style={organizationCard} className="dashboard-section-card">
                <p style={cardDesc} className="card-desc">
                  <b>Plan Revenue (MRR)</b>
                </p>
                <h4 style={cardTitle} className="card-title">
                  {`$${Number(totalSubsRevenue)
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    .toLocaleString("en-US")}`}
                </h4>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Card style={organizationCard} className="dashboard-section-card">
                <p style={cardDesc} className="card-desc">
                  <b>Project Revenue (MPR) - Credits</b>
                </p>
                <h4 style={cardTitle} className="card-title">
                  {`$ ${Number(totalSimpleProductRev)
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    .toLocaleString("en-US")}`}
                </h4>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Card style={organizationCard} className="dashboard-section-card">
                <p style={cardDesc} className="card-desc">
                  <b>Total Workspaces</b>
                </p>
                <h4 style={cardTitle} className="card-title">
                  {allWorkSpaces}
                </h4>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Card style={organizationCard} className="dashboard-section-card">
                <p style={cardDesc} className="card-desc">
                  <b>Total Users</b>
                </p>
                <h4 style={cardTitle} className="card-title">
                  {totalUsers}
                </h4>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Card style={organizationCard} className="dashboard-section-card">
                <p style={cardDesc} className="card-desc">
                  <b>Top 3 Workspaces</b>
                </p>
                <List>
                  {topRevenueWorkspaces?.map((workspace, index) => (
                    <ListItem key={workspace.id}>
                      <ListItemText
                        primary={`${index + 1}. ${workspace?.name}`}
                        // secondary={`Revenue: $${workspace.revenue.toFixed(2)}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Card>
            </Grid>
            
          </Grid>
          <div className="space"></div>
          <Grid container spacing={5} className="footer-container">
            <Grid item xs={12}>
              <Footer />
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default WorkspaceStatistics;
