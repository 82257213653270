import React, { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPageSpeedLoading,
  setPageSpeedData,
  setPageSpeedLoading,
  showToast,
} from "../redux/userSlice";
import PageSpeedGuage from "./Common/PageSpeedGuage";
import HealthCheck from "../Assets/healthcheck-icon.png";
import { useNavigate } from "react-router-dom";
import "../Css/Dashboard.css";
import LanguageIcon from "@mui/icons-material/Language";
import RefreshIcon from "@mui/icons-material/Refresh";
import { updateWorkspaceInfo } from "../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import { uploadData } from "aws-amplify/storage";
import axios from "axios";

import pako from "pako";
const buttonStyle = {
  border: "none",
  color: "#000000de",
  textTransform: "initial",
  fontSize: "1rem",
  backgroundColor: "#F0F0F0",
};
const PerformanceMetrics = ({
  data,
  domain,
  setDomain,
  selectedWorkspaceId,
  fetchWorkspaceInfo,
}) => {
  const isLoading = useSelector(selectPageSpeedLoading);
  const client = generateClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("user");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const userData = JSON.parse(token);
  const calculateTotalScore = () => {
    if (!data) return 0;
    const total =
      ((data.performance || 0) +
        (data.accessibility || 0) +
        (data.bestPractices || 0) +
        (data.seo || 0)) /
      4;
    return total.toFixed(2);
  };

  const metrics = [
    { value: data?.performance || 0, label: "Performance" },
    { value: data?.accessibility || 0, label: "Accessibility" },
    { value: data?.bestPractices || 0, label: "Best Practices" },
    { value: data?.seo || 0, label: "SEO" },
  ];
  const runPageSpeedAnalysis = async (domainUrl) => {
    dispatch(setPageSpeedLoading(true));
    try {
      const api_key = "AIzaSyBtL911PwgosKvUClz7OnUpf9V8PCEknDs";
      const response = await axios.get(
        `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${encodeURIComponent(
          domainUrl
        )}&key=${api_key}&category=performance&category=accessibility&category=best-practices&category=seo`
      );

      const { data } = response;
      const { lighthouseResult } = data;
      // Process all audits with detailed information
      const processedAudits = Object.entries(lighthouseResult.audits).map(
        ([key, audit]) => ({
          id: key,
          title: audit.title,
          description: audit.description,
          score: audit.score,
          displayValue: audit.displayValue,
          numericValue: audit.numericValue,
          details: {
            ...audit.details,
            opportunity: getOpportunityText(audit),
            items: processAuditItems(audit.details?.items),
          },
          warnings: audit.warnings,
          type: audit.scoreDisplayMode,
          recommendations: getRecommendations(audit),
        })
      );
      const categorizedIssues = {
        errors: processedAudits.filter(
          (audit) =>
            audit.score !== null &&
            audit.score < 0.5 &&
            audit.type !== "informative"
        ),
        warnings: processedAudits.filter(
          (audit) =>
            audit.score !== null &&
            audit.score >= 0.5 &&
            audit.score < 0.9 &&
            audit.type !== "informative"
        ),
        notices: processedAudits.filter(
          (audit) =>
            (audit.score !== null && audit.score >= 0.9) ||
            audit.type === "informative"
        ),
      };

      const metrics = {
        performance: Math.round(
          (data.lighthouseResult.categories.performance?.score || 0) * 100
        ),
        accessibility: Math.round(
          (data.lighthouseResult.categories.accessibility?.score || 0) * 100
        ),
        bestPractices: Math.round(
          (data.lighthouseResult.categories["best-practices"]?.score || 0) * 100
        ),
        seo: Math.round(
          (data.lighthouseResult.categories.seo?.score || 0) * 100
        ),
      };

      const report = {
        url: domainUrl,
        timestamp: new Date().toISOString(),
        metrics: {
          performance: Math.round(
            (lighthouseResult.categories.performance?.score || 0) * 100
          ),
          accessibility: Math.round(
            (lighthouseResult.categories.accessibility?.score || 0) * 100
          ),
          bestPractices: Math.round(
            (lighthouseResult.categories["best-practices"]?.score || 0) * 100
          ),
          seo: Math.round((lighthouseResult.categories.seo?.score || 0) * 100),
        },
        issues: categorizedIssues,
        // metrics:metrics,
        // environment: lighthouseResult.environment,
        // configSettings: lighthouseResult.configSettings
      };

      // Convert report object to Blob/File
      const reportBlob = new Blob([JSON.stringify(report)], {
        type: "application/json",
      });
      const fileName = `pagespeed-report.json`;
      const reportFile = new File([reportBlob], fileName, {
        type: "application/json",
      });

      // Upload report using consistent path to overwrite existing file
      const upload = await uploadData({
        key: `${selectedWorkspaceId}/${userData.sub}/pagespeed-reports/pagespeed-report.json`,
        data: reportFile,
        options: {
          accessLevel: "public",
        },
      });

      // Wait for the upload to complete and get the result
      const uploadResult = await upload.result;
      console.log("Upload completed:", uploadResult);

      // Store the report location and metrics in the database
      const reportLocation = uploadResult.key || uploadResult.url;
      await client.graphql({
        query: updateWorkspaceInfo,
        variables: {
          input: {
            id: selectedWorkspaceId,
            page_speed_report: JSON.stringify({
              reportUrl: reportLocation,
              metrics: metrics,
              lastUpdated: new Date().toISOString(),
            }),
          },
        },
      });

      dispatch(setPageSpeedData(metrics));
      return metrics;
    } catch (error) {
      console.error("Error fetching PageSpeed data", error);
      dispatch(
        showToast({
          message:
            "Failed to analyze domain. Please check the URL and try again.",
          type: "error",
        })
      );
      return null;
    } finally {
      dispatch(setPageSpeedLoading(false));
      setIsRefreshing(false);
    }
  };

  const compressReport = async (report) => {
    console.log("REport::,report", report);
    // Convert report to JSON string
    const jsonString = JSON.stringify(report);

    // Compress using pako
    const compressed = pako.deflate(jsonString);

    // Convert to base64
    return btoa(String.fromCharCode.apply(null, compressed));
  };

  const decompressReport = (compressedBase64) => {
    // Convert base64 to Uint8Array
    const compressedData = new Uint8Array(
      atob(compressedBase64)
        .split("")
        .map((char) => char.charCodeAt(0))
    );

    // Decompress
    const decompressedString = pako.inflate(compressedData, { to: "string" });

    // Parse back to JSON
    return JSON.parse(decompressedString);
  };

  // Helper functions
  const getOpportunityText = (audit) => {
    if (audit.details?.overallSavingsMs) {
      return `Potential savings of ${audit.details.overallSavingsMs}ms`;
    }
    if (audit.details?.overallSavingsBytes) {
      return `Potential savings of ${(
        audit.details.overallSavingsBytes / 1024
      ).toFixed(2)}KB`;
    }
    return null;
  };
  const processAuditItems = (items) => {
    if (!items) return null;

    return items.map((item) => ({
      ...item,
      url: item.url || item.source,
      totalBytes: item.totalBytes || item.wastedBytes,
      wastedMs: item.wastedMs,
      source: item.source,
    }));
  };
  const getRecommendations = (audit) => {
    // Custom recommendations based on audit type
    const recommendations = {
      "first-contentful-paint": [
        "Eliminate render-blocking resources",
        "Minimize main-thread work",
        "Reduce server response time",
      ],
      "speed-index": [
        "Optimize images",
        "Minimize critical request depth",
        "Reduce JavaScript execution time",
      ],
      // Add more audit-specific recommendations
    };

    return recommendations[audit.id] || [];
  };

  const handleRefresh = async () => {
    if (!domain) {
      dispatch(
        showToast({
          message: "Please enter a domain name",
          type: "error",
        })
      );
      return;
    }

    setIsRefreshing(true);
    const domainName = domain.replace(/^(https?:\/\/)?(www\.)?/, "https://");

    try {
      // Update workspace info with new domain if changed
      await client.graphql({
        query: updateWorkspaceInfo,
        variables: {
          input: {
            id: selectedWorkspaceId,
            samrushDomainName: domainName,
          },
        },
      });

      // Force a new PageSpeed analysis
      await runPageSpeedAnalysis(domainName);
    } catch (error) {
      console.error("Error updating workspace:", error);
      dispatch(
        showToast({
          message: "Failed to update workspace information",
          type: "error",
        })
      );
    }
  };
  return (
    <Box
      sx={{
        backgroundColor: "white",
        // p: 3,
        borderRadius: 1,
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 3,
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img src={HealthCheck} style={{ height: 50, width: 50 }} />
          <h4
            className="card-title"
            style={{
              fontSize: "1.3rem",
              margin: "0px",
              marginTop: "0rem",
              marginLeft: "1rem",
            }}
          >
            Health Check
          </h4>
        </Box>
        <Box sx={{ width: "30%", ml: "auto" }}>
          {" "}
          {/* Fixed width container */}
          <TextField
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            variant="outlined"
            fullWidth
            label="Domain"
            sx={{
              borderRadius: 5,
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#f8fafc",
                "&:hover": {
                  backgroundColor: "#f1f5f9",
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LanguageIcon sx={{ color: "#64748b" }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {/* Regenerate Button */}
        <IconButton
          onClick={handleRefresh}
          sx={{
            marginLeft: 0.5,
            borderRadius: 1,
            backgroundColor: (theme) => theme.palette.primary.main,
            color: "white",
            padding: "15px",
            width: 50,
            "&:hover": {
              backgroundColor: "#1e7ab0",
            },
            "&.Mui-disabled": {
              backgroundColor: "#e0e0e0",
              color: "#9e9e9e",
            },
          }}
        >
          <RefreshIcon />
        </IconButton>
        <Button
          sx={{ marginLeft: 5 }}
          variant="outlined"
          style={buttonStyle}
          onClick={() => navigate("/performance")}
          className="manage-sub-btn"
        >
          Learn More
        </Button>
      </Box>

      {/* Metrics Display */}
      <Grid container spacing={3}>
        {/* Total Score */}
        <Grid item xs={12} md={4}>
          <Box sx={{ textAlign: "center" }}>
            <PageSpeedGuage
              value={calculateTotalScore()}
              size={130}
              thickness={10}
            />
            <Typography
              variant="h6"
              component={"body"}
              sx={{ mt: 1, color: "#666" }}
            >
              Total Digital Performance Score
            </Typography>
          </Box>
        </Grid>

        {/* Individual Metrics */}
        <Grid sx={{ padding: 0 }} item xs={12} md={8}>
          <Grid container spacing={1}>
            {metrics.map((metric, index) => (
              <Grid item xs={6} key={index}>
                <div style={{ textAlign: "center", padding: 5 }}>
                  <PageSpeedGuage
                    value={metric.value}
                    size={80}
                    thickness={6}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      fontSize: "14px",
                      color: "#666",
                    }}
                  >
                    {metric.label}
                  </Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PerformanceMetrics;
