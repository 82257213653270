import React from "react";
import {
  Box,
  Card,
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import RefreshIcon from "@mui/icons-material/Refresh";
import HelpIcon from "@mui/icons-material/Help";
import HealthCheck from "../Assets/healthcheck-icon.png";
import { styled } from "@mui/material/styles";
import { LockIcon, UnlockIcon } from "../utills/Svgs/svg";
import { useSelector } from "react-redux";
import PageSpeedGuage from "../components/Common/PageSpeedGuage";
import { selectPageSpeedData } from "../redux/userSlice";
import Advisor from "../Assets/1721063651853.jpg";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import { toast } from "react-toastify";

// Custom styled components
const HeaderCard = styled(Box)({
  border: "1px solid #48a6c2",
  borderRadius: "8px",
  padding: "12px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "24px",
  // backgroundColor: '#fff'
});

const GradientCard = styled(Box)({
  background: "linear-gradient(80deg, #ED2A13 0%, #48A6C2 100%)",
  borderRadius: "8px",
  padding: "20px",
  color: "#fff",
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#fff",
  },
  flex: 1,
  marginRight: "16px",
});
const organizationCard = {
  margin: "0.7rem 0rem",
  padding: "1rem 1.5rem",
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  minHeight: "330px",
  borderRadius: "10px",
};
const HealthCheckDashboard = (props) => {
  const { domain, handleClick, setWorkSpace, hasSubscription } = props;
  const data = useSelector(selectPageSpeedData);
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const calculateTotalScore = () => {
    if (!data) return 0;
    const total =
      ((data.performance || 0) +
        (data.accessibility || 0) +
        (data.bestPractices || 0) +
        (data.seo || 0)) /
      4;
    return total.toFixed(2);
  };
  const metrics = [
    { value: data?.performance || 0, label: "Performance" },
    { value: data?.accessibility || 0, label: "Accessibility" },
    { value: data?.bestPractices || 0, label: "Best Practices" },
    { value: data?.seo || 0, label: "SEO" },
  ];
  return (
    <Box sx={{ padding: 3 }}>
      {/* Header */}
      <HeaderCard>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img src={HealthCheck} style={{ height: 50, width: 50 }} />
          <Box>
            <Typography p={1} fontWeight={"bold"} variant="h5">
              Health Check
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Checked on December 10, 2024
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <StyledTextField
            onChange={(e) => setWorkSpace(e.target.value)}
            value={domain}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LanguageIcon sx={{ color: "#64748b" }} />
                </InputAdornment>
              ),
            }}
            placeholder="www.yourdomain.com"
            size="small"
          />
          <Button
            onClick={handleClick}
            startIcon={<RefreshIcon />}
            variant="contained"
            sx={{ backgroundColor: "#0FA250" }}
          >
            Run Complete Audit
          </Button>
          <IconButton>
            <HelpIcon />
          </IconButton>
        </Box>
      </HeaderCard>

      {/* Main Content */}
      <Grid container spacing={3}>
        {/* Gauge Card */}
        <Grid item xs={4}>
          <Card style={organizationCard}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PageSpeedGuage
                value={calculateTotalScore()}
                size={130}
                thickness={10}
              />
              <Typography sx={{ mt: 2 }}>
                Total Digital Performance Score
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 2,
              }}
            >
              <Grid sx={{ padding: 0 }} item xs={12} md={8}>
                <Grid container spacing={1}>
                  {metrics.map((metric, index) => (
                    <Grid item xs={6} key={index}>
                      <div style={{ textAlign: "center" }}>
                        <PageSpeedGuage
                          value={metric.value}
                          size={70}
                          thickness={6}
                        />
                        <Typography
                          sx={{
                            mt: 1,
                            fontSize: "14px",
                            color: "#666",
                          }}
                        >
                          {metric.label}
                        </Typography>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>

        {/* Issues Card */}
        <Grid item xs={8}>
          <Card style={organizationCard}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                height: "100%",
              }}
            >
              {/* Issues Section */}
              <Box
                sx={{
                  position: "relative",
                  height: "100%",
                  // opacity: 0.5,
                  flex: 1,
                }}
              >
                {/* Content Section */}
                <Box
                  sx={{
                    alignItems: "center",
                    flex: 1,
                    height: "100%",
                    position: "relative",
                    zIndex: 1,
                    // opacity: 0.2,
                    filter: "blur(2px)",
                    WebkitFilter: "blur(2px)", // For Safari support
                    "& > *": {
                      // This will apply to all direct children
                      filter: "blur(1.5px)",
                      WebkitFilter: "blur(1.5px)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography variant="subtitle1">Issues</Typography>
                    <Typography ml={"auto"} variant="subtitle1">
                      45
                    </Typography>
                  </Box>
                  <div style={{ display: "flex" }}>
                    <div>
                      <Typography variant="body2" sx={{ mt: 3 }}>
                        System status is currently under maintenance. System
                        status is currently under maintenance
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 3 }}>
                        Page speed is optimal but could be improved
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 3 }}>
                        Security protocols need to be updated
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 3 }}>
                        Performance metrics show room for optimization
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 3 }}>
                        Content delivery can be enhanced
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 3 }}>
                        Server response time needs attention
                      </Typography>
                    </div>
                    <div style={{ marginLeft: "auto" }}>
                      <Typography variant="body2" sx={{ mt: 3 }}>
                        2{" "}
                      </Typography>{" "}
                      <Typography variant="body2" sx={{ mt: 3 }}>
                        4{" "}
                      </Typography>{" "}
                      <Typography variant="body2" sx={{ mt: 3 }}>
                        5{" "}
                      </Typography>{" "}
                      <Typography variant="body2" sx={{ mt: 3 }}>
                        6{" "}
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 3 }}>
                        7{" "}
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 3 }}>
                        8{" "}
                      </Typography>
                    </div>
                  </div>
                </Box>

                {/* Lock Overlay */}
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    zIndex: 2,
                  }}
                >
                  <LockIcon />
                </Box>
              </Box>

              {/* Gradient Card Section */}
              <GradientCard
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <div>
                    <Typography variant="h6">Unlock 100% Score</Typography>
                    <Typography sx={{ opacity: 0.8 }} variant="p">
                      Subscribe our package.
                    </Typography>
                  </div>
                  <UnlockIcon height={30} width={30} />
                </Box>

                <Divider sx={{ backgroundColor: "whitesmoke", my: 2 }} />

                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 2,
                      color: "black",
                      backgroundColor: "white",
                      p: 1.5,
                      borderRadius: 2,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={HealthCheck}
                        style={{ height: 30, width: 30 }}
                      />
                      <Typography>
                        <strong>Health</strong>Check+
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      {hasSubscription?.length ? (
                        <>
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() =>
                              toast.success(
                                "You are already subscribed the package. Please run the complete audit for the best results."
                              )
                            }
                          >
                            Subscribed
                          </Button>
                          <Typography>
                            <strong>
                              Please Run the complete <br></br> audit for the
                              best results.
                            </strong>
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => navigate(`/shop/healthcheck-plus`)}
                          >
                            Subscribe
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => navigate(`/shop/healthcheck-plus`)}
                            sx={{
                              color: "gray",
                              borderColor: "#fff",
                              "&:hover": {
                                borderColor: "#f5f5f5",
                                backgroundColor: "rgba(255,255,255,0.1)",
                              },
                              textDecoration: "underline",
                            }}
                          >
                            VIEW DETAILS
                          </Button>
                        </>
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        alignItems: "center",
                        gap: 1,
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={Advisor}
                        height="100px"
                        width="100px"
                        style={{
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <h3
                            style={{
                              margin: "0px",
                              textAlign: "center",
                              color: "black",
                            }}
                          >
                            Marcus Frye
                          </h3>
                        </div>
                        <div style={{ color: "white" }}>
                          marcus@rothbright.com
                        </div>
                      </div>
                    </Box>
                    <Button
                      onClick={() =>
                        window.open(
                          "https://calendly.com/d/cpng-nc4-t7n/rothbright-advisor-calendar",
                          "_blank"
                        )
                      }
                      variant="outlined"
                      sx={{
                        color: "#fff",
                        borderColor: "#fff",
                        "&:hover": {
                          borderColor: "#f5f5f5",
                          backgroundColor: "rgba(255,255,255,0.1)",
                        },
                      }}
                    >
                      Connect with Advisor
                    </Button>
                  </Box>
                </Box>
              </GradientCard>
            </Box>
          </Card>
        </Grid>

        {/* Bottom Row Cards */}
        <Grid item xs={4}>
          <Card style={organizationCard}>
            <Box
              sx={{
                position: "relative",
                height: "100%",
                // opacity: 0.5,
                flex: 1,
              }}
            >
              {/* Content Section */}
              <Box
                sx={{
                  alignItems: "center",
                  flex: 1,
                  height: "100%",
                  position: "relative",
                  zIndex: 1,
                  // opacity: 0.2,
                  filter: "blur(2px)",
                  WebkitFilter: "blur(2px)", // For Safari support
                  "& > *": {
                    // This will apply to all direct children
                    filter: "blur(1.5px)",
                    WebkitFilter: "blur(1.5px)",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography variant="subtitle1">SEO</Typography>
                  <Typography ml={"auto"} variant="subtitle1">
                    45
                  </Typography>
                </Box>
                <div style={{ display: "flex" }}>
                  <div>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      System status is currently under maintenance. System
                      status is currently under maintenance
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      Page speed is optimal but could be improved
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      Security protocols need to be updated
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      Performance metrics show room for optimization
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      Content delivery can be enhanced
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      Server response time needs attention
                    </Typography>
                  </div>
                  <div style={{ marginLeft: "auto" }}>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      2{" "}
                    </Typography>{" "}
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      4{" "}
                    </Typography>{" "}
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      5{" "}
                    </Typography>{" "}
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      6{" "}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      7{" "}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      8{" "}
                    </Typography>
                  </div>
                </div>
              </Box>

              {/* Lock Overlay */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  zIndex: 2,
                }}
              >
                <LockIcon />
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <GradientCard
            style={organizationCard}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                alignSelf: "center",
              }}
            >
              <UnlockIcon
                style={{ marginLeft: "3.5rem" }}
                height={80}
                width={80}
              />

              <Typography variant="h6">Unlock 100% Score</Typography>
              <Typography variant="p">Subscribe to our package.</Typography>
            </div>

            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 2,
                  color: "black",
                  backgroundColor: "white",
                  p: 1.5,
                  borderRadius: 2,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img src={HealthCheck} style={{ height: 30, width: 30 }} />
                  <Typography>
                    <strong>Health</strong>Check+
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  {hasSubscription?.length ? (
                    <>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() =>
                          toast.success(
                            "You are already subscribed the package. Please run the complete audit for the best results."
                          )
                        }
                      >
                        Subscribed
                      </Button>
                      <Typography>
                        <strong>
                          Please Run the complete <br></br> audit for the best
                          results.
                        </strong>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => navigate(`/shop/healthcheck-plus`)}
                      >
                        Subscribe
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => navigate(`/shop/healthcheck-plus`)}
                        sx={{
                          color: "gray",
                          borderColor: "#fff",
                          "&:hover": {
                            borderColor: "#f5f5f5",
                            backgroundColor: "rgba(255,255,255,0.1)",
                          },
                          textDecoration: "underline",
                        }}
                      >
                        VIEW DETAILS
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </GradientCard>
        </Grid>
        <Grid item xs={4}>
          <Card style={organizationCard}>
            <Box
              sx={{
                position: "relative",
                height: "100%",
                // opacity: 0.5,
                flex: 1,
              }}
            >
              {/* Content Section */}
              <Box
                sx={{
                  alignItems: "center",
                  flex: 1,
                  height: "100%",
                  position: "relative",
                  zIndex: 1,
                  // opacity: 0.2,
                  filter: "blur(2px)",
                  WebkitFilter: "blur(2px)", // For Safari support
                  "& > *": {
                    // This will apply to all direct children
                    filter: "blur(1.5px)",
                    WebkitFilter: "blur(1.5px)",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography variant="subtitle1">Accessibility</Typography>
                  <Typography ml={"auto"} variant="subtitle1">
                    45
                  </Typography>
                </Box>
                <div style={{ display: "flex" }}>
                  <div>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      System status is currently under maintenance. System
                      status is currently under maintenance
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      Page speed is optimal but could be improved
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      Security protocols need to be updated
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      Performance metrics show room for optimization
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      Content delivery can be enhanced
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      Server response time needs attention
                    </Typography>
                  </div>
                  <div style={{ marginLeft: "auto" }}>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      2{" "}
                    </Typography>{" "}
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      4{" "}
                    </Typography>{" "}
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      5{" "}
                    </Typography>{" "}
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      6{" "}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      7{" "}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      8{" "}
                    </Typography>
                  </div>
                </div>
              </Box>

              {/* Lock Overlay */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  zIndex: 2,
                }}
              >
                <LockIcon />
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
      {!isMobile && (
        <Grid mt={"auto"} container spacing={5}>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default HealthCheckDashboard;
