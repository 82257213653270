import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { stripeService } from "../utills/stripeApis/stripeApi";

const CreateInvoiceForm = ({ 
  createInvoice, 
  productData,
  plansById,
  setError 
}) => {
  const [formData, setFormData] = useState({
    customer: '',
    email: '',
    amount: '',
    description: '',
    productId: '',
    planId: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [prices, setPrices] = useState({});
  const [availablePlans, setAvailablePlans] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const getPlansForProduct = (productId) => {
    if (!productId || !plansById) return [];
    return plansById.filter(plan => plan.product_id === productId);
  };

  useEffect(() => {
    if (formData.productId) {
      const plans = getPlansForProduct(formData.productId);
      setAvailablePlans(plans);
      
      // Find the selected product
      const product = productData.find(p => p.id === formData.productId);
      setSelectedProduct(product);
      
      // If product has a fixed price, set it automatically
      if (product?.type !== "variable subscription" && product?.price) {
        setFormData(prev => ({
          ...prev,
          amount: product.price,
          planId: '' // Clear plan selection for fixed price products
        }));
      }
    }
  }, [formData.productId, plansById, productData]);

  const handleProductChange = async (event) => {
    const productId = event.target.value;
    setFormData(prev => ({
      ...prev,
      productId,
      planId: '',
      amount: '' // Reset amount when product changes
    }));

    try {
      const pricesData = await stripeService.getPrices(productId);
      setPrices(prev => ({ ...prev, [productId]: pricesData }));
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    }
  };

  const handlePlanChange = (event) => {
    const planId = event.target.value;
    const selectedPlan = availablePlans.find(plan => plan.id === planId);
    
    setFormData(prev => ({
      ...prev,
      planId,
      ...(selectedPlan?.price && { amount: selectedPlan.price })
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!emailRegex.test(formData.email)) {
      setError("Please enter a valid email address");
      return;
    }

    setIsSubmitting(true);

    try {
      const customer = await stripeService.findOrCreateCustomer(formData.email);
      
      await createInvoice({
        customerId: customer.id,
        customerEmail: formData.email,
        priceId: selectedProduct?.type !== "variable subscription" ? null : formData.planId,
        description: formData.description,
        amount: formData.amount
      });
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
        <TextField
          label="Customer Name"
          value={formData.customer}
          onChange={(e) => setFormData(prev => ({ ...prev, customer: e.target.value }))}
          required
          fullWidth
        />

        <TextField
          label="Customer Email"
          type="email"
          value={formData.email}
          onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
          required
          fullWidth
          error={formData.email && !emailRegex.test(formData.email)}
          helperText={formData.email && !emailRegex.test(formData.email) ? "Please enter a valid email address" : ""}
        />

        <FormControl fullWidth required>
          <InputLabel>Product</InputLabel>
          <Select
            value={formData.productId}
            onChange={handleProductChange}
            label="Product"
          >
            <MenuItem value="">Select a product</MenuItem>
            {productData?.map(product => (
              <MenuItem key={product.id} value={product.id}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Only show plan selection for variable subscription products */}
        {selectedProduct?.type === "variable subscription" && (
          <FormControl fullWidth required disabled={!formData.productId}>
            <InputLabel>Plan</InputLabel>
            <Select
              value={formData.planId}
              onChange={handlePlanChange}
              label="Plan"
            >
              <MenuItem value="">Select a plan</MenuItem>
              {availablePlans.map(plan => (
                <MenuItem key={plan.id} value={plan.id}>
                  {plan.name} {plan.price ? `($${plan.price})` : ''}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <TextField
          label="Amount ($)"
          type="number"
          inputProps={{ step: "0.01", min: "0" }}
          value={formData.amount}
          onChange={(e) => setFormData(prev => ({ ...prev, amount: parseFloat(e.target.value) }))}
          required
          fullWidth
          // disabled={selectedProduct?.type !== "variable subscription" && selectedProduct?.price}
        />

        <TextField
          label="Description"
          value={formData.description}
          onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
          required
          fullWidth
          multiline
          rows={4}
        />

        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Creating Invoice...' : 'Create Invoice'}
        </Button>
      </Box>
    </form>
  );
};

export default CreateInvoiceForm;