import { Modal, Box, Typography, Button, List, ListItem, ListItemText, Divider } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import React from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '80vh',
  overflow: 'auto',
};

const AllIssuesModal = ({ open, handleClose, issues, onIssueClick }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2" gutterBottom>
          All Issues
        </Typography>
        <List>
          {issues.map((issue, index) => (
            <React.Fragment key={index}>
              <ListItem 
                button 
                onClick={() => onIssueClick(issue)}
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
              >
                {issue.severity === "errors" ? (
                  <ErrorIcon sx={{ color: "#FF4444" }} />
                ) : (
                  <WarningIcon sx={{ color: "#FFA726" }} />
                )}
                <ListItemText 
                  primary={issue.title}
                  secondary={issue.description}
                />
              </ListItem>
              {index < issues.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
        <Button 
          sx={{ mt: 3 }} 
          variant="contained" 
          onClick={handleClose}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default AllIssuesModal;
