import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  CircularProgress,
  Alert,
  Box,
  Chip
} from '@mui/material';
import {
  Add as AddIcon,
  Description as DescriptionIcon,
  AttachMoney as MoneyIcon,
  TrendingUp as TrendingUpIcon,
  AccessTime as ClockIcon
} from '@mui/icons-material';
import CreateInvoiceForm from '../components/create_invoice_form';
import { stripeService } from '../utills/stripeApis/stripeApi';
import { toast } from 'react-toastify';
import { listProductCards, listProductVariations } from '../graphql/queries';
import { generateClient } from 'aws-amplify/api';
import { useDispatch } from 'react-redux';
import { showToast } from '../redux/userSlice';
const container = {
    margin: "2rem 4rem",
  };
  const organizationCard = {
    margin: "0.7rem 0rem",
    padding: "1rem 1.5rem",
    boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
    minHeight: "330px",
    borderRadius: "10px",
  };

const InvoiceManagement = () => {
    const client = generateClient();
    const dispatch = useDispatch();
  
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');

    const [itemSelect, setItemSelect] = useState(null);
    const [productData, setProductData] = React.useState([]);
    const [plansById, setPlansById] = useState(null);
    const [product, setProduct] = useState([]);
    const [price, setPrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [netPrice, setNetPrice] = React.useState(0);
    const [count, setCount] = useState(1);
  
  // Mock data
 


  useEffect(() => {
    fetchInitialData();
    fetchProductsDetail();
    fetchPlansById();
  }, []);

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const [productsData, invoicesData] = await Promise.all([
        stripeService.getProducts(),
        stripeService.getInvoices()
      ]);
      
      setProduct(productsData);
      
      // Transform the Stripe invoice data into the format we need
      const transformedInvoices = invoicesData.map(invoice => ({
        id: invoice.id,
        customer: invoice.customer?.email || invoice.customer_email || 'N/A',
        amount: invoice.total / 100, // Convert from cents to dollars
        status: invoice.status,
        date: new Date(invoice.created * 1000).toLocaleDateString("en-US", { 
            year: "numeric", 
            month: "long", 
            day: "numeric" 
          }),
        stripeInvoiceId: invoice.id,
        product: invoice.lines?.data[0]?.description || 'N/A',
        plan: invoice.lines?.data[0]?.plan?.interval || 'One-Time',
        url: invoice?.hosted_invoice_url
      }));
      
      setInvoices(transformedInvoices);
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    } finally {
      setLoading(false);
    }
  }
  const handleProductChange = (event, newValue) => {
    setSelectedProduct(newValue);
    if (productData?.length > 0) {
      const selectedItem = productData.filter((pro) => pro.name === newValue);
      if (selectedItem?.[0]?.type !== "variable subscription") {
        setPrice(selectedItem?.[0]?.price);
        setTotalPrice(selectedItem?.[0]?.price);
        setNetPrice(selectedItem?.[0]?.price);
        setCount(1);
        setItemSelect(selectedItem);
        return;
      }
      setItemSelect(selectedItem);
    }
    setPrice(0);
    setTotalPrice(0);
    setNetPrice(0); 
    setCount(1);
  };
   async function fetchAllProductVariationsIds() {
      let allItems = [];
      let nextToken = null;
  
      do {
        const variables = {
          filter: {
            product_id: {
              eq: itemSelect?.[0]?.id,
            },
          },
          nextToken: nextToken,
        };
        const getCategories = await client.graphql({
          query: listProductVariations,
          variables: variables,
        });
        const response = getCategories?.data?.listProductVariations?.items || [];
        allItems = [...allItems, ...response];
        nextToken = getCategories?.data?.listProductVariations?.nextToken;
      } while (nextToken);
      return allItems;
    }
    const fetchPlansById = async () => {
      try {
        const response = await fetchAllProductVariationsIds();
        const cleanedResponse = response.map((item) => {
          const cleanedItem = { ...item };
          if (cleanedItem.categories) {
            cleanedItem.categories = JSON.parse(cleanedItem.categories);
          }
          if (cleanedItem.tags) {
            cleanedItem.tags = JSON.parse(cleanedItem.tags);
          }
          return cleanedItem;
        });
        setPlansById(cleanedResponse);
      } catch (error) {
        dispatch(showToast({ message: error.message, type: "error" }));
        console.error("Error fetching orders:", error);
      }
    };
  
  const fetchProductsDetail = async () => {
    try {
      const getProductData = await client.graphql({
        query: listProductCards,
      });

      const result = getProductData?.data?.listProductCards?.items;
      const sortedResult = result?.sort((a, b) => a.name.localeCompare(b.name));
      setProductData(sortedResult);
    } catch (error) {
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };
  const createInvoice = async (formData) => {
    setLoading(true);
    try {
      const newInvoice = await stripeService.createInvoice({
        customer: formData.customerId,  // Use the customer ID directly
        priceId: formData.priceId,
        description: formData.description,
        amount: formData.amount,
        email: formData.customerEmail
      });
      
      // Refresh invoices list
    
      fetchInitialData();
      setIsCreateDialogOpen(false);
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    } finally {
      setLoading(false);
    }
  };
  // Statistics cards data
  const stats = {
    totalAmount: invoices?.reduce((sum, inv) => sum + inv?.amount, 0),
    pendingInvoices: invoices?.filter(inv => inv?.status !== 'paid').length,
    recentInvoices: invoices?.slice(0, 3)
  };

  const handleInvoiceView = (invoice_url) => {
    if(invoice_url){
        window.open(invoice_url, '_blank');}
        else{
            toast.error("Invoice not available. Please try again later or contact support.")
        }

    }

  return (
    <div style={container} className="main-container">    {/* Stats Cards */}
    <Grid container spacing={3} sx={{ mb: 3 }}>
      <Grid item xs={12} md={4}>
        <Card > 
          <CardHeader
            title={
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography fontWeight="bold" variant="h6">Total Revenue</Typography>
                <MoneyIcon color="action" />
              </Box>
            }
          />
          <CardContent>
            <Typography variant="h4">${stats.totalAmount}.00</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardHeader
            title={
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography fontWeight="bold" variant="h6">Pending Invoices</Typography>
                <ClockIcon color="action" />
              </Box>
            }
          />
          <CardContent>
            <Typography variant="h4">{stats.pendingInvoices}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardHeader
            title={
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography fontWeight="bold" variant="h6">Recent Activity</Typography>
                <TrendingUpIcon color="action" />
              </Box>
            }
          />
          <CardContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {stats.recentInvoices.map(inv => (
                <Box key={inv.id} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body2">{inv.customer}</Typography>
                  <Typography variant="body2">${inv.amount}</Typography>
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>

    {/* Main Invoice Management Card */}
    <Card style={organizationCard} className="dashboard-section-card">
      <CardHeader fsx={{fontWeight: 'bold'}}
        title="Invoice Management"
        subheader="Create and manage invoices linked with Stripe"
        action={
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setIsCreateDialogOpen(true)}
          >
            New Invoice
          </Button>
        }
      />
      <CardContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {loading && !error ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}}>Invoice ID</TableCell>
                  <TableCell sx={{fontWeight: 'bold'}}>Customer</TableCell>
                  <TableCell sx={{fontWeight: 'bold'}}>Description</TableCell>
                  <TableCell  sx={{fontWeight: 'bold'}}>Plan</TableCell>
                  <TableCell sx={{fontWeight: 'bold'}}>Amount</TableCell>
                  <TableCell sx={{fontWeight: 'bold'}}>Status</TableCell>
                  <TableCell sx={{fontWeight: 'bold'}}>Date</TableCell>
                  <TableCell sx={{fontWeight: 'bold'}}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((invoice) => (
                    <TableRow key={invoice.id}>
                  
                    <TableCell>{invoice.id}</TableCell>
                    <TableCell>{invoice.customer}</TableCell>
                    <TableCell>{invoice.product}</TableCell>
                    <TableCell>{invoice.plan}</TableCell>
                    <TableCell>${invoice.amount}</TableCell>
                    <TableCell>
                      <Chip
                        label={invoice.status}
                        color={invoice.status === 'paid' ? 'success' : 'warning'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>{invoice.date}</TableCell>
                    <TableCell>
                      <Button
                      onClick={() => handleInvoiceView(invoice.url)}
                        size="small"
                        startIcon={<DescriptionIcon />}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CardContent>
    </Card>

    {/* Create Invoice Dialog */}
    <Dialog
      open={isCreateDialogOpen}
      onClose={() => setIsCreateDialogOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Create New Invoice</DialogTitle>
      <DialogContent>
        <CreateInvoiceForm  createInvoice={createInvoice}
  productData={productData}
  plansById={plansById}
  setError={setError} />
      </DialogContent>
    </Dialog>
  </div>
  );
};

export default InvoiceManagement;