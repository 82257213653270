import axios from "axios";

export const createStripeCustomer = async (name, email) => {
  try {
    const url = "https://api.stripe.com/v1/customers";
    const data = {
      name: name,
      email: email,
    };

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
      },
    };

    const params = new URLSearchParams(data).toString();

    const response = await axios.post(url, params, config);
    console.log("Stripe customer created:", response.data);
    return response.data.id;
  } catch (error) {
    console.error(
      "Error creating Stripe customer:",
      error.response ? error.response.data : error.message
    );
    return null;
  }
};

export const stripeApi = axios.create({
  baseURL: "https://api.stripe.com/v1",
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

export const attachPaymentMethod = async (customerId, paymentMethodId) => {
  try {
    const response = await stripeApi.post(
      `/payment_methods/${paymentMethodId}/attach`,
      new URLSearchParams({
        customer: customerId,
      })
    );
    return response.data;
  } catch (error) {
    console.error("Error attaching PaymentMethod:", error);
    throw error;
  }
};

export const createPaymentIntent = async (
  totalAmount,
  customerId,
  paymentMethodId,
  creditsPurpose
) => {
  try {
    const response = await stripeApi.post("/payment_intents", {
      amount: Math.round(totalAmount * 100), // Convert to cents
      currency: "usd",
      customer: customerId,
      payment_method: paymentMethodId,
      off_session: true,
      confirm: true,
      description: creditsPurpose
    });
    return response;
  } catch (error) {
    console.error("Error creating PaymentIntent:", error);
    throw error;
  }
};

export const createPrice = async (stripeProduct, product, intervalCount) => {
  try {
    const response = await stripeApi.post("/prices", {
      product: stripeProduct.id,
      unit_amount: Math.round(parseFloat(product.price) * 100),
      currency: "usd",
      recurring: {
        interval: product.period,
        interval_count: intervalCount,
      },
    });
    return response;
  } catch (error) {
    console.error("Error creating Price:", error);
    throw error;
  }
};

export const createSetupIntent = async (customerId) => {
  try {
    const response = await axios.post(
      "https://api.stripe.com/v1/setup_intents",
      `customer=${customerId}&usage=off_session`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response.data.client_secret;
  } catch (error) {
    console.error(
      "Error creating SetupIntent:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const createPaymentMethod = async (cardDetails) => {
  try {
    const response = await axios.post(
      "https://api.stripe.com/v1/payment_methods",
      {
        type: "card",
        card: {
          number: cardDetails.number,
          exp_month: cardDetails.exp_month,
          exp_year: cardDetails.exp_year,
          cvc: cardDetails.cvc,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating PaymentMethod:", error);
    throw error;
  }
};

export const updateCustomerDefaultPaymentMethod = async (
  customerId,
  paymentMethodId
) => {
  console.log("The strip data is:::", customerId, paymentMethodId)
  try {
    const response = await axios.post(
      `https://api.stripe.com/v1/customers/${customerId}`,
      `invoice_settings[default_payment_method]=${paymentMethodId}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating customer default payment method:", error);
    throw error;
  }
};

export const updateStripeCustomer = async (customerId, name, email) => {
  try {
    const url = `https://api.stripe.com/v1/customers/${customerId}`;
    const data = {
      name: name,
      email: email,
    };

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
      },
    };

    const params = new URLSearchParams(data).toString();
    const response = await axios.post(url, params, config);

    console.log("Stripe customer updated:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating Stripe customer:", error.response?.data || error.message);
    throw error;
  }
};


export const stripeService = {
  // Create a customer
  createCustomer: async (email, name) => {
    try {
      const data = new URLSearchParams({
        email,
        name,
      }).toString();

      const response = await stripeApi.post('/customers', data);
      return response.data;
    } catch (error) {
      console.error('Error creating customer:', error.response?.data || error.message);
      throw error;
    }
  },

  // Get all products
  getProducts: async () => {
    try {
      const response = await stripeApi.get('/products', {
        params: {
          active: true,
          expand: ['data.default_price'],
        },
      });
      return response.data.data;
    } catch (error) {
      console.error('Error fetching products:', error.response?.data || error.message);
      throw error;
    }
  },

  // Get prices for a product
  getPrices: async (productId) => {
    try {
      const response = await stripeApi.get('/prices', {
        params: {
          product: productId,
          active: true,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error('Error fetching prices:', error.response?.data || error.message);
      throw error;
    }
  },
  findOrCreateCustomer: async (email) => {
    try {
      // First, search for existing customer
      const searchParams = new URLSearchParams({
        query: `email:'${email}'`,
        limit: '1'
      }).toString();
      
      const searchResponse = await stripeApi.get(`/customers/search?${searchParams}`);
      
      if (searchResponse.data.data.length > 0) {
        return searchResponse.data.data[0]; // Return existing customer
      }

      // If no customer found, create new one
      const customerData = new URLSearchParams({
        email: email
      }).toString();

      const createResponse = await stripeApi.post('/customers', customerData);
      return createResponse.data;
    } catch (error) {
      console.error('Error finding/creating customer:', error.response?.data || error.message);
      throw error;
    }
  },
  // Create an invoice

   createInvoice : async (invoiceData) => {
    console.log("Creating invoice with data:", invoiceData);
    try {
      // Convert amount to cents
      const amountInCents = Math.round(parseFloat(invoiceData.amount) * 100);
  
      // Create the invoice
      const invoice = await stripeApi.post('/invoices', {
        customer: invoiceData.customer,
        auto_advance: true,
        collection_method: 'send_invoice',
        days_until_due: 30
      });
      console.log("Initial invoice created:", invoice.data);
  
      // Create invoice item
      const invoiceItem = await stripeApi.post('/invoiceitems', {
        customer: invoiceData.customer,
        invoice: invoice.data.id,
        unit_amount: amountInCents,
        currency: 'usd',
        description: invoiceData.description,
        quantity: 1
      });
      console.log("Invoice item created:", invoiceItem.data);
  
      // Retrieve updated invoice
      const updatedInvoice = await stripeApi.get(`/invoices/${invoice.data.id}`);
      console.log("Updated invoice with items:", updatedInvoice.data);
  
      // Finalize the invoice
      const finalizedInvoice = await stripeApi.post(`/invoices/${invoice.data.id}/finalize`);
      console.log("Finalized invoice:", finalizedInvoice.data);
  
      // Send the invoice with error checking
      if (finalizedInvoice.data.status !== 'draft') {
        const sentInvoice = await stripeApi.post(`/invoices/${invoice.data.id}/send`);
        console.log("Invoice sent successfully:", sentInvoice.data);
        
        // Verify invoice status after sending
        if (sentInvoice.data.status === 'open') {
          console.log("Invoice sent and opened successfully");
        } else {
          console.warn("Invoice sent but status is:", sentInvoice.data.status);
        }
        
        return sentInvoice.data;
      } else {
        throw new Error('Invoice could not be finalized');
      }
  
    } catch (error) {
      // Enhanced error logging
      console.error('Error in invoice creation/sending:', {
        message: error.message,
        type: error.type,
        stripeError: error.response?.data?.error,
        stack: error.stack
      });
      
      // Rethrow with more context
      throw new Error(`Failed to process invoice: ${error.message}`);
    }
  },

  // Get all invoices
  getInvoices: async () => {
    try {
      const response = await stripeApi.get('/invoices', {
        params: {
          limit: 30,
          expand: ['data.customer', 'data.subscription'],
        },
      });
      return response.data.data;
    } catch (error) {
      console.error('Error fetching invoices:', error.response?.data || error.message);
      throw error;
    }
  },

  // Get a specific invoice
  getInvoice: async (invoiceId) => {
    try {
      const response = await stripeApi.get(`/invoices/${invoiceId}`, {
        params: {
          expand: ['customer', 'subscription'],
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching invoice:', error.response?.data || error.message);
      throw error;
    }
  },
};
